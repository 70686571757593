@charset "UTF-8";
@import "variables";
@import "mixins";

.m_pagetitle {
  background-color: #E2F3F8;
  .m_breadcumbs + & {
    margin-top: 10px;
    @include mq(tbl) {
      margin-top: 18px;
    }
  }
  .inner {
    @include container;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    @include mq(tbl) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    &:after {
      @include mq(tbl) {
        @include pd;
        background: center / contain no-repeat url(#{$imagepath}pagetitle_bg.png);
        right: calc(50% - (#{$container} / 2));
        width: 421px;
        height: 153px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .title {
      @include fs(22,34);
      font-weight: $font-weight-bold;
      position: relative;
      letter-spacing: 2px;
      @include mq(tbl) {
        @include fs(32,46);
        letter-spacing: 3px;
      }
    }
  }
}
