@charset "UTF-8";
@import "variables";
@import "mixins";

.m_searchresult {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  .inner {
    @include container;
    .list {
      margin-top: 40px;
      @include mq(tbl) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fit, 1fr);
        grid-gap: 40px 45px;
      }
      .unit {
        display: flex;
        position: relative;
        margin-top: 30px;
        gap: 20px;
        @include mq(tbl) {
          margin-top: 0;
          gap: 28px;
        }
        &:first-child {
          margin-top: 0;
        }
        .image {
          flex: 0 1 auto;
          a {
            display: block;
            @include mq(tbl) {
              @include hoverOp;
            }
            img {
              width: 120px;
              border-radius: 5px;
              @include mq(tbl) {
                width: 160px;
              }
            }
          }
          .icons {
            margin-top: 10px;
            display: flex;
            align-items: center;
            li {
              width: 25px;
              @include mq(tbl) {
                width: 32px;
              }
            }
          }
        }
        .desc {
          flex: 1;
          .title {
            @include fs(16,24);
            position: relative;
            padding-left: 22px;
            color: $main-dark;
            font-weight: $font-weight-bold;
            @include mq(tbl) {
              padding-left: 26px;
              @include fs(18,26);
            }
            &:before {
              @include pd;
              background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
              width: 16px;
              height: 16px;
              top: 12px;
              left: 0;
              transform: translateY(-50%);
              @include mq(tbl) {
                top: 15px;
              }
            }
            a {
              color: $main-dark;
            }
            @include mq(tbl) {
              @include fs(18,28);
              @include hoverOp;
            }
          }
          table {
            @include fs(12,16);
            margin-top: 6px;
            @include mq(tbl) {
              @include fs(14,22);
              margin-top: 12px;
            }
            th {
              white-space: nowrap;
              padding: 0 10px 4px 0;
              vertical-align: top;
              font-weight: $font-weight-bold;
              @include mq(tbl) {
                padding-bottom: 8px;
              }
            }
            td {
              padding: 0 0 4px 0;
              @include mq(tbl) {
                padding-bottom: 8px;
              }
            }
          }
        }
      }
    }
    .more {
      margin-top: 40px;
      text-align: center;
    }
  }
}
