@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_movie{
  margin-top: 25px;
  @include mq(tbl) {
    margin-top: 40px;
  }
  .inner {
    @include container;
    .movie {
      margin: 0 auto;
      .movie_wrap_outer {
        width: 100%;
        &.shrink {
          margin-left: auto;
          margin-right: auto;
        }
        .movie_wrap_inner {
          padding-top: 56.25%;
          position: relative;
          display: block;
          width: 100%;
          @include mq(tbl) {
            width: 100%;
          }
          iframe {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }
        }
      }
      .caption {
        &.shrink {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
