@charset "UTF-8";
@import "variables";
@import "mixins";

.m_articletitle {
  background-color: #EBE9E1;
  padding: 30px 0 35px;
  @include mq(tbl) {
    padding: 50px 0 55px;
  }
  &.with_eyecatch {
    padding-bottom: 85px;
    @include mq(tbl) {
      padding-bottom: 145px;
    }
  }
  .inner {
    @include container;
    .cat {
      margin-bottom: 20px;
      font-weight: $font-weight-bold;
      @include mq(tbl) {
        margin-bottom: 15px;
      }
      li {
        display: inline;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
        a {
          text-decoration: underline;
          color: $color-main;
          @include mq(tbl) {
            @include fs(16,26);
          }
        }
      }
    }
    .title {
      @include fs(20,30);
      font-family: $font-family-main;
      font-weight: $font-weight-bold;
      @include mq(tbl) {
        @include fs(28,40);
      }
    }
    .date {
      margin-top: 10px;
      font-family: $font-family-sub;
      color: #666666;
      @include fs(13,13);
      @include mq(tbl) {
        margin-top: 20px;
        @include fs(15,15);
      }
    }
  }
}
