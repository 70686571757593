@charset "UTF-8";
@import "variables";
@import "mixins";

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider {
  .slick-track, .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:before {
    display: table;
    content: '';
  }
  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide {
  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}


.m_slider {
  .unit {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    @include mq(tbl) {
      display: block;
      height: 450px;
    }
    @include mq(pc) {
      height: 550px;
    }
    > a {
      display: block;
      position: relative;
      z-index: 1;
    }
    /*
    &::before {
      @include pd;
      @include mq(tbl) {
        z-index: 11;
        width: $container;
        height: 408px;
        background-color: $black;
        opacity: .5;
        transition: opacity .2s ease;
        margin-left: 10px;
      }
      @include mq(pc) {
        width: $container-wide;
        height: 500px;
      }
    }
    */
    .text {
      padding: 17px 20px;
      background-color: $main-light;
      @include mq(tbl) {
        background-color: transparent;
        position: absolute;
        width: 400px;
        left: 100px;
        bottom: 55px;
        padding: 0;
        z-index: 10;
      }
      &.right {
        text-align: right;
        @include mq(tbl) {
          left: auto;
          right: 100px;
        }
        a {
          @include mq(tbl) {
            margin-left: auto;
          }
        }
      }
      &.center {
        text-align: center;
        @include mq(tbl) {
          text-align: center;
          width: 400px;
          left: 50%;
          transform: translateX(-50%);
        }
        a {
          margin-right: auto;
        }
      }
      .textinner {
        display: inline-block;
      }
      h1 {
        @include fs(18,28);
        color: $white;
        font-weight: $font-weight-bold;
        @include mq(tbl) {
          @include fs(30,44);
          letter-spacing: 3px;
        }
        + p {
          margin-top: 10px;
          @include mq(tbl) {
            margin-top: 20px;
          }
        }
      }
      p {
        @include fs(13,21);
        color: $black;
        font-weight: $font-weight-bold;
        margin: 0;
        @include mq(tbl) {
          @include fs(16,35);
          color: $white;
          letter-spacing: 2px;
        }
      }
      &.black {
        h1, p {
          @include mq(tbl) {
            color: $black;
          }
        }
      }
      a {
        @include button(small);
        margin-top: 12px;
        border: none;
        @include mq(tbl) {
          margin-top: 25px;
          @include button(medium);
          background-color: $white;
          border: 2px solid $main;
          color: $main-dark !important;
          letter-spacing: 3px;
          &:before {
            background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
          }
        }
        /*
        background-color: $sub;
        @include fs(12,12);
        font-weight: $font-weight-bold;
        color: $white;
        display: block;
        padding: 12px 75px 12px 30px;
        position: relative;
        color: $black;
        width: 150px;
        margin-left: auto;
        @include mq(tbl) {
          margin-top: 30px;
          text-align: center;
          width: 200px;
          margin-left: 0;
          @include fs(14,14);
          padding: 14px 75px;
          @include hoverOp;
        }
        &:before {
          @include pd;
          background: url(#{$imagepath}arrow_right_large.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 28px;
          height: 16px;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
        }
        */
      }
    }
    .bg {
      width: 100%;
      height: 318px;
      background-position: center;
      background-size: cover;
      transition: opacity .2s ease;
      @include mq(tbl) {
        display: block;
        height: 450px;
      }
      @include mq(pc) {
        height: 550px;
      }
    }
    &.slick-active::before, &.is-active-next::before {
      opacity: 0;
      z-index: 0;
    }
    .bg.pc {
      display: none;
      @include mq(tbl) {
        display: block;
      }
    }
    .bg.sp {
      display: block;
      @include mq(tbl) {
        display: none;
      }
    }
    &.with_bg {
      &.left {
        @include mq(tbl) {

        }
      }
      &.right {
        @include mq(tbl) {

        }
      }
      .text {
        .textinner {
          @include mq(tbl) {
            display: inline-block;
          }
          h1 {
            @include mq(tbl) {
              background-color: $white;
              display: inline-block;
              padding: 18px 35px;
              border-radius: 20px;
            }
          }
          p {
            @include mq(tbl) {
              background-color: $white;
              display: inline-block;
              padding: 18px 30px;
              margin-top: 10px;
              border-radius: 20px;
              @include fs(14,28);
            }
          }
          a {
            @include mq(tbl) {
              margin-top: 0;
              position: relative;
              z-index: 2;
            }
          }
        }
        &.left {
          h1 {
            @include mq(tbl) {
              transform: translateX(115px);
            }
          }
          a {
            @include mq(tbl) {
              transform: translate(273px, -10px);
            }
          }
        }
        &.center {
          a {
            @include mq(tbl) {
              transform: translateY(-10px);
            }
          }
        }
        &.right {
          p {
            @include mq(tbl) {
              transform: translateX(-35px);
            }
          }
          a {
            @include mq(tbl) {
              transform: translateY(-10px);
            }
          }
        }
      }
    }
  }
  .slick-arrow {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 20px;
    z-index: 10;
    cursor: pointer;
    display: none !important;
    @include mq(tbl) {
      display: block !important;
      top: 50%;
      height: 44px;
      transform: translateY(-100%);
      @include hoverOp(.4);
    }
    @include mq(pc) {

    }
    img {
      width: 15px;
      transform: rotate(180deg);
      @include mq(tbl) {
        width: 25px;
      }
    }
    &.slick-next {
      left: auto;
      right: 20px;
      @include mq(tbl) {
        left: auto;
      }
      @include mq(pc) {
      }
      img {
        transform: rotate(0deg);
      }
    }
  }
  .slick-dots {
    margin: 20px 0 11px;
    @include slickdots;
    @include mq(tbl) {
      text-align: right;
      margin: 25px 30px 0 0;
    }
  }
}
