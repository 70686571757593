@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_text {
  margin-top: 20px;
  @include mq(tbl) {
    margin-top: 35px;
  }
  .inner {
    @include container;
    .text {
      @include mq(tbl) {
        padding-left: 100px;
        padding-right: 100px;
      }
    }
  }
  &.lead,
  &.lead_large {
    margin-top: 30px;
    @include mq(tbl) {
      margin-top: 55px;
    }
    .m_cattitle + & {
      margin-top: 40px;
      @include mq(tbl) {
        margin-top: 65px;
      }
    }
    .inner {
      .text {
        color: $sub-dark;
        @include mq(tbl) {
          padding: 0;
        }
        p {
          text-align: center;
          @include fs(22,34);
          font-family: $font-family-main;
          font-weight: $font-weight-bold;
          @include mq(tbl) {
            @include fs(28,46);
          }
        }
      }
    }
  }
  &.sp_left {
    text-align: left;
  }
  &.sp_center {
    text-align: center;
  }
  &.sp_right {
    text-align: right;
  }
  &.pc_left {
    @include mq(tbl) {
      text-align: left;
    }
  }
  &.pc_center {
    @include mq(tbl) {
      text-align: center;
    }
  }
  &.pc_right {
    @include mq(tbl) {
      text-align: right;
    }
  }
}
