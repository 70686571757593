@charset "UTF-8";
@import "variables";
@import "mixins";

.m_pagenate {
  margin-top: 60px;
  @include container;
  @include mq(tbl) {
    margin-top: 100px;
  }
  .m_newslist + & {
    margin-top: 40px;
    @include mq(tbl) {
      margin-top: 50px;
    }
    .inner {
      padding-top: 0;
      border-top: none;
    }
  }
  .inner {
    @include fs(12,12);
    font-weight: $font-weight-bold;
    @include mq(tbl) {
      @include fs(14,14);
    }
    a, span {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      display: block;
      border-radius: 50%;
      background-color: #EBEBEB;
      @include mq(tbl) {
        @include hoverOp;
        width: 50px;
        height: 50px;
        line-height: 50px;
      }
      img {
        width: 6px;
        vertical-align: middle;
        @include mq(tbl) {
          width: 9px;
        }
      }
      &.current, &.current_page {
        background-color: $color-main;
        color: white;
        @include mq(tbl) {
          &:hover {
            opacity: 1;
          }
        }
      }
      &.previouspostslink, &.link_before {
        img {
          transform: rotate(180deg);
        }
      }
    }
    // wordpress
    .wp-pagenavi {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a, span {
        margin: 0 3px 15px;

      }
    }
    // mt
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        margin: 0 5px 15px;
        @include mq(tbl) {
          margin: 0 10px 20px;
        }
      }
    }
  }
}
.m_pagenate_detail {
  margin-top: 50px;
  @include container;
  @include mq(tbl) {
    margin-top: 80px;
  }
  .inner {
    padding-top: 40px;
    padding-bottom: 65px;
    position: relative;
    border-top: 1px solid #ccc;
    font-family: $font-family-main;
    display: flex;
    justify-content: space-between;
    @include mq(tbl) {
      padding-top: 60px;
      padding-bottom: 0;
      justify-content: center;
    }
    span {
      display: inline-block;
      @include mq(tbl) {
        min-width: 120px;
      }
      &.prev, &.next {
        a {
          position: relative;
          padding-right: 50px;
          &:before {
            @include pd;
            background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
            width: 16px;
            height: 16px;
            top: 50%;
            right: 20px;
            transform-origin: center;
            transform: translateY(-50%);
          }
        }
      }
      &.prev {
        a {
          padding-left: 50px;
          padding-right: 35px;
          &:before {
            right: auto;
            left: 20px;
            transform-origin: top center;
            transform: rotate(180deg) translateY(-50%);
          }
        }
      }
      &.back {
        position: absolute;
        bottom: 0;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        @include mq(tbl) {
          position: static;
          transform: translateX(0);
          margin: 0 20px;
        }
      }
      a {
        @include button(large);
        padding: 6px 35px 6px 35px;
        border-radius: 25px;
        text-decoration: none !important;
        font-weight: $font-weight-bold;
      }
    }
  }
}
