@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general {
  strong, b {
    font-weight: $font-weight-bold;
  }
  a {
    color: $color-main;
    text-decoration: underline;
  }
  .text {
    p {
      @include fs(15,28);
      font-family: $font-family-main;
      padding-bottom: 1.5em;
      @include mq(tbl) {
        @include fs(18,34);
      }
      &.note {
        @include fs(12,18);
        color: rgba($body-text-color, 0.5);
        @include mq(tbl) {
          @include fs(15,30);
        }
      }
      &.heading {
        @include fs(22,32);
        font-family: $font-family-main;
        padding-bottom: 1em;
        color: $main-dark;
        font-weight: $font-weight-bold;
        @include mq(tbl) {
          @include fs(25,38);
          padding-bottom: 1em;
          padding-top: .5em;
        }
      }
      &.figure {
        @include fs(12,18);
        margin-top: 30px;
        padding-bottom: 35px;
        @include mq(tbl) {
          @include fs(15,26);
          margin-top: 60px;
          padding-bottom: 65px;
        }
        img {
          &.mt-image-left,
          &.mt-image-center,
          &.mt-image-right,
          &.mt-image-none {
            margin-bottom: 6px !important;
            display: block;
            margin-left: auto;
            margin-right: auto;
            @include mq(tbl) {
              margin-bottom: 12px !important;
            }
          }
        }
      }
      img {
        &.mt-image-left,
        &.mt-image-center,
        &.mt-image-right {
          margin-bottom: 0 !important;
        }
      }
      &:last-child {
        padding-bottom: 0;
      }
      & + .button{
        margin-top: 0;
      }
    }
    ul, ol {
      @include fs(15,28);
      font-family: $font-family-main;
      padding-bottom: 2em;
      @include mq(tbl) {
        @include fs(18,34);
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
    ul {
      list-style: disc;
      li {
        margin-left: 20px;
      }
    }
    ol {
      list-style: decimal;
      li {
        margin-left: 20px;
      }
    }
    .services {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(auto-fit, 1fr);
      grid-gap: 10px 10px;
      @include mq(tb) {
        grid-template-columns: repeat(3, 1fr);
      }
      li {
        border-radius: 25px;
        margin: 0;
        border: 1px solid #DBDBDB;
        padding: 4px 20px 4px 13px;
        display: flex;
        align-items: center;
        @include fs(12,18);
        font-weight: $font-weight-bold;
        @include mq(tbl) {
          @include fs(14,18);
        }
        &:last-child {
          margin-right: 0;
        }
        i {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 7px;
          @include mq(tbl) {
            width: 36px;
            height: 36px;
          }
          img {
            width: 100%;
          }
        }
      }
    }
    .icons {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      li {
        border-radius: 25px;
        margin: 0 10px 10px 0;
        border: 1px solid #DBDBDB;
        padding: 5px 22px;
        font-weight: $font-weight-bold;
        @include fs(12,18);
        @include mq(tbl) {
          padding: 7px 24px;
          @include fs(14,22);
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .button {
      margin-top: 10px;
      text-align: center;
      @include button($size:medium);
      @include mq(tbl) {
        margin-top: 15px;
      }
    }
  }
  .image, .movie, .map {
    text-align: center;
    img {
      border-radius: 5px;
      @include mq(tbl) {
        border-radius: 10px;
      }
    }
    .caption {
      @include fs(12,20);
      text-align: center;
      margin-top: 8px;
      color: rgba($body-text-color, 0.75);
      font-weight: $font-weight-bold;
      @include mq(tbl) {
        @include fs(13,22);
        margin-top: 16px;
      }
      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
    }
    .child {
      padding-bottom: 15px;
      @include mq(tbl) {
        padding-bottom: 35px;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

@import "general/heading";
@import "general/text";
@import "general/text_image";
@import "general/text_map";
@import "general/map";
@import "general/image";
@import "general/image_multi";
@import "general/movie";
@import "general/panel";
@import "general/speaker";
@import "general/column";
@import "general/table";
@import "general/button";
@import "general/anchor";
@import "general/num";
@import "general/wysiwyg";
@import "general/catalog";
@import "general/icon";
