@charset "UTF-8";
@import "variables";
@import "mixins";

.tippy-box[data-theme~='green'] {
  background-color: $main-dark;
  color: $white;
  font-weight: $font-weight-bold;
  line-height: 1.6;
  .tippy-arrow {
    color: $main-dark;
  }
}
