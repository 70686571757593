@charset "UTF-8";
@import "variables";
@import "mixins";

#recruit_banner {
  position: fixed;
  right: 0;
  top: 240px;
  z-index: 9999;
  @include mq(tbl) {
    top: 254px;
  }
  img {
    width: 57px;
    @include mq(tbl) {
      width: 60px;
    }
  }
  a {
    @include mq(tbl) {
      @include hoverOp;
    }
  }
}
.obj {
  position: absolute;
  img {
    width: 90px;
    @include mq(tbl) {
      width: 175px;
    }
  }
}
.top_news {
  margin-top: 30px;
  overflow: hidden;
  @include mq(tbl) {
    margin-top: 60px;
  }
  .inner {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    @include mq(tbl) {
      padding-left: calc(50% - 460px);
      padding-right: calc(50% - 460px);
    }
    .obj {
      display: none;
      @include mq(tbl) {
        display: block;
        left: calc(50% - 680px);
        top: 18px;
      }
    }
    .head {
      display: flex;
      align-items: flex-end;
      border-bottom: 1px solid $black;
      padding-bottom: 12px;
      position: relative;
      height: 50px;
      @include mq(tbl) {
        padding-bottom: 20px;
        height: 91px;
      }
      &:before {
        @include pd;
        background: center / contain no-repeat url(#{$imagepath}top_news.png);
        width: 80px;
        height: 50px;
        bottom: -1px;
        right: 0;
        @include mq(tbl) {
          width: 151px;
          height: 91px;
        }
      }
      .title {
        padding: 0 0 0 3px;
        h1 {
          @include fs(16,16);
          font-weight: $font-weight-bold;
          @include mq(tbl) {
            @include fs(18,18);
          }
        }
      }
      .link {
        border-left: 1px solid #8C8887;
        padding: 2px 0 2px 13px;
        margin-left: 13px;
        @include mq(tbl) {
          padding-left: 22px;
          margin-left: 22px;
        }
        a {
          position: relative;
          padding-left: 20px;
          display: block;
          @include fs(12,12);
          @include hoverOp;
          font-weight: $font-weight-bold;
          @include mq(tbl) {
            @include fs(14,14);
            padding-left: 22px;
          }
          &:before {
            @include pd;
            background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
            width: 13px;
            height: 13px;
            top: 0px;
            left: 0;
            @include mq(tbl) {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
    .m_newslist {
      margin-top: 0;
      @include mq(tbl) {
        margin-top: 20px;
      }
      .inner {
        padding: 0;
        ul {
          border: none;
          li {
            border: none;
            a {
              padding-bottom: 4px;
              @include mq(tbl) {
                padding-top: 12px;
              }
            }
          }
        }
      }
    }
  }
}
.top_customer {
  margin-top: 50px;
  overflow: hidden;
  @include mq(tbl) {
    margin-top: 110px;
  }
  .inner {
    position: relative;
    @include mq(tbl) {
      padding-left: calc(50% - 570px);
      padding-right: calc(50% - 570px);
    }
    .obj_01 {
      right: -26px;
      top: 60px;
      @include mq(tbl) {
        right: calc(50% - 680px);
        top: 300px;
      }
    }
    .obj_02 {
      left: -26px;
      top: 405px;
      @include mq(tbl) {
        left: calc(50% - 680px);
        top: auto;
        bottom: 135px;
      }
    }
    .title {
      text-align: center;
      h1 {
        @include fs(24,36);
        font-weight: $font-weight-bold;
        letter-spacing: 4px;
        @include mq(tbl) {
          @include fs(34,46);
        }
      }
      p {
        @include fs(10,18);
        margin-top: 6px;
        @include mq(tbl) {
          @include fs(17,27);
          margin-top: 12px;
        }
      }
    }
    .list {
      position: relative;
      z-index: 2;
      padding-bottom: 30px;
      @include mq(tbl) {
        display: flex;
        padding-bottom: 80px;
        margin-top: 85px;
      }
      .unit {
        background-color: $white;
        filter: drop-shadow(0px 0px 10px rgba(0,0,0,.09));
        border-radius: 10px;
        transform: translateX(-15px);
        text-align: right;
        padding: 30px 22px 35px 35px;
        max-width: 560px;
        margin: 40px auto 0;
        @include mq(tbl) {
          flex: 0 1 auto;
          transform: translateX(0);
          padding: 0 35px 130px;
          width: 380px;
          margin: 0;
          position: relative;
        }
        &:nth-child(even){
          transform: translateX(15px);
          text-align: left;
          padding: 30px 35px 35px 22px;
          @include mq(tbl) {
            transform: translateX(0);
            padding: 0 35px 50px;
          }
          a {
            margin-left: 0;
            margin-right: auto;
          }
        }
        .icon {
          position: absolute;
          max-width: 150px;
          @include mq(tbl) {
            position: static;
            text-align: center;
            max-width: none;
            margin: 0 auto;
          }
        }
        &.left {
          @include mq(tbl) {
            transform: translate(20px, 50px);
          }
          .icon {
            top: -12px;
            left: 40px;
            width: 28vw;
            @include mq(tbl) {
              width: 217px;
              transform: translateY(-36px);
            }
          }
          h2 {
            color: $sub;
          }
          a {
            &:before {
              background-image: url(#{$imagepath}arrow_right_sub.svg);
            }
          }
        }
        &.center {
          @include mq(tbl) {
            z-index: 3;
          }
          .icon {
            top: -10px;
            right: 26px;
            width: 28vw;
            @include mq(tbl) {
              width: 200px;
              transform: translateY(-30px);
            }
          }
          h2 {
            color: $main;
          }
          a {
            border-color: $main;
            &:before {
              background-image: url(#{$imagepath}arrow_right_main.svg);
            }
          }
        }
        &.right {
          @include mq(tbl) {
            transform: translate(-20px, 50px);
          }
          .icon {
            top: -5px;
            left: 25px;
            width: 29vw;
            @include mq(tbl) {
              width: 246px;
              transform: translateY(-30px);
            }
          }
          h2 {
            color: $third;
            .sp {
              @include mq(tbl) {
                display: none;
              }
            }
            .pc {
              display: none;
              @include mq(tbl) {
                display: inline;
              }
            }
          }
          a {
            border-color: $third;
            &:before {
              background-image: url(#{$imagepath}arrow_right_third.svg);
            }
          }
        }
        h2 {
          @include fs(22,30);
          letter-spacing: 2px;
          font-weight: $font-weight-bold;
          @include mq(tbl) {
            text-align: center;
            @include fs(28,42);
          }
        }
        p {
          @include fs(13,22);
          margin-top: 12px;
          @include mq(tbl) {
            margin-top: 20px;
            text-align: center;
            @include fs(14,26);
          }
        }
        a {
          @include button();
          background-color: $white;
          border: 1px solid $sub;
          color: $black !important;
          margin-top: 25px;
          display: block;
          text-align: left;
          max-width: 325px;
          padding-left: 25px;
          @include fs(14,26);
          margin-left: auto;
          @include mq(tbl) {
            margin: 0;
            position: absolute;
            bottom: 50px;
            width: 300px;
          }
        }
      }
    }
  }
}
.top_banners {
  margin-top: 40px;
  overflow: hidden;
  @include mq(tbl) {
    margin-top: 70px;
    padding-top: 60px;
    padding-bottom: 100px;
  }
  .inner {
    position: relative;
    @include container;
    .obj_01 {
      right: -26px;
      bottom: 0;
      @include mq(tbl) {
        right: calc(50% - 680px);
        bottom: -100px;
      }
    }
    .obj_02 {
      left: -26px;
      top: 40%;
      z-index: 4;
      @include mq(tbl) {
        left: calc(50% - 680px);
        top: auto;
        bottom: 125px;
      }
    }
    .obj_03 {
      display: none;
      @include mq(tbl) {
        display: block;
        left: calc(43%);
        top: -60px;
      }
    }
    .list {
      @include mq(tbl) {
        display: flex;
        justify-content: space-between;
      }
      .unit {
        max-width: 550px;
        margin: 0 auto;
        position: relative;
        @include mq(tbl) {
          width: 494px;
        }
        .image {
          width: 77vw;
          max-width: 494px;
          position: relative;
          z-index: 2;
          margin-right: 14px;
          @include mq(tbl) {
            width: 494px;
            margin-right: 0;
          }
        }
        .link {
          position: absolute;
          z-index: 3;
          a {
            @include button();
            background-color: $white;
            color: $black !important;
            @include fs(12,24);
            padding: 7px 43px 7px 22px;
            filter: drop-shadow(0px 3px 6px rgba(0,0,0,.16));
            @include mq(tbl) {
              @include hoverOp(.9);
              @include fs(15,25);
              padding: 12px 70px 12px 30px;
              letter-spacing: 2px;
            }
            &:before {
              background-image: url(#{$imagepath}arrow_right_main.svg);
              width: 13px;
              height: 13px;
              @include mq(tbl) {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
        .text {
          border: 1px solid $main;
          border-radius: 10px;
          width: 84vw;
          max-width: 494px;
          @include mq(tbl) {
            width: 494px;
          }
          h2 {
            color: $main-dark;
            @include fs(18,28);
            letter-spacing: 2px;
            font-weight: $font-weight-bold;
            @include mq(tbl) {
              @include fs(28,42);
            }
          }
          p {
            font-weight: $font-weight-bold;
            @include fs(12,18);
            margin-top: 5px;
            @include mq(tbl) {
              @include fs(15,25);
              margin-top: 8px;
            }
          }
        }
        &.top {
          .image {
            margin-left: auto;
            @include mq(tbl) {
              padding-right: 25px;
            }
          }
          .link {
            right: 0;
            transform: translateY(-20px);
            @include mq(tbl) {
              transform: translateY(-22px);
            }
          }
          .text {
            transform: translateY(-44px);
            padding: 60px 24px 24px;
            @include mq(tbl) {
              transform: translate(-54px,-75px);
              padding: 100px 50px 50px 76px;
            }
          }
        }
        &.bottom {
          display: flex;
          flex-direction: column-reverse;
          @include mq(tbl) {
            padding-top: 70px;
          }
          .image {
            margin-right: auto;
            transform: translateY(-44px);
            @include mq(tbl) {
              transform: translateY(0);
            }
          }
          .link {
            right: 0;
            bottom: 60px;
            @include mq(tbl) {
              bottom: 25px;
              right: -20px;
            }
          }
          .text {
            margin-left: auto;
            padding: 20px 24px 60px;
            text-align: right;
            @include mq(tbl) {
              transform: translate(54px,75px);
              padding: 35px 60px 110px 50px;
            }
          }
        }
      }
    }
  }
}
.top_search_title {
  margin-top: 20px;
  .image {
    text-align: center;
    .sp {
      max-width: 447px;
      @include mq(tbl) {
        display: none;
      }
    }
    .pc {
      display: none;
      @include mq(tbl) {
        display: inline;
        max-width: 1280px;
        width: 100%;
      }
    }
  }
  h1 {
    text-align: center;
    @include fs(20,30);
    font-weight: $font-weight-bold;
    letter-spacing: 4px;
    margin-top: 30px;
    @include mq(tbl) {
      @include fs(30,42);
    }
  }
}
