@charset "UTF-8";
@import "variables";
@import "mixins";

.m_filter {
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  .inner {
    @include container;
    ul {
      display: flex;
      flex-wrap: wrap;
      @include mq(tbl) {
        justify-content: center;
      }
      li {
        text-align: center;
        margin: 0 6px 15px 0;
        @include mq(tbl) {
          margin: 0 5px 15px;
        }
        &.active {
          a {
            background-color: $color-main;
            border-color: $color-main;
            color: white;
          }
        }
        a {
          @include button($size:medium);
        }
      }
    }
  }
}
