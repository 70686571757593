@import "variables";

@mixin pd {
  position: absolute;
  content: '';
  display: block;
}
@mixin mq($breakpoint: tbl) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin sp_pc {
  &.sp {
    @include mq(tbl) {
      display: none;
    }
  }
  &.pc {
    display: none;
    @include mq(tbl) {
      display: block;
    }
  }
}
@mixin container($sp:15px,$type:normal) {
  padding-left: $sp;
  padding-right: $sp;
  @include mq(tbl) {
    padding-left: calc(50% - (#{$container} / 2));
    padding-right: calc(50% - (#{$container} / 2));
  }
  @if $type == wide {
    @include mq(pc) {
      padding-left: calc(50% - (#{$container-wide} / 2));
      padding-right: calc(50% - (#{$container-wide} / 2));
    }
  }
  @if $type == narrow {
    @include mq(pc) {
      padding-left: calc(50% - (#{$container-narrow} / 2));
      padding-right: calc(50% - (#{$container-narrow} / 2));
    }
  }
}
@mixin title {
  @include fs(24,32);
  padding: 6px 0 10px 18px;
  border-bottom: 1px solid #ccc;
  font-weight: $font-weight-bold;
  position: relative;
  @include mq(tbl) {
    @include fs(28,40);
    padding: 14px 0 22px 32px;
    &.large {
      @include fs(34,40);
    }
  }
  &:before {
    @include pd;
    width: 4px;
    top: 0;
    left: 0;
    background-color: $main;
    bottom: 6px;
    @include mq(tbl) {
      width: 6px;
      bottom: 12px;
    }
  }
}
@mixin button($size:medium) {
  display: inline-block;
  background-color: $main;
  color: $white !important;
  @include fs(16,28);
  padding: 6px 50px 6px 35px;
  border-radius: 25px;
  text-decoration: none !important;
  font-weight: $font-weight-bold;
  position: relative;
  &:before {
    @include pd;
    background: center / contain no-repeat url(#{$imagepath}arrow_right_white.svg);
    width: 16px;
    height: 16px;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  @include mq(tbl) {
    @include hoverOp(.5);
    padding: 10px 50px 10px 35px;
  }
  @if $size == small {
    @include fs(12,16);
    padding: 7px 15px;
    border-radius: 20px;
    color: $black !important;
    background-color: $white;
    border: 1px solid #DBDBDB;
    &:before {
      content: none;
    }
    @include mq(tbl) {
      @include fs(14,18);
      padding: 7px 24px;
    }
  }
  @else if $size == large {
    border-radius: 25px;
    background-color: $white;
    color: $black !important;
    border: 1px solid $main;
    padding: 8px 35px;
    @include fs(14,24);
    &:before {
      content: none;
    }
    @include mq(tbl) {
      padding: 8px 35px;
      @include fs(16,28);
    }
  }
  @else if $size == apply {
    background-color: $red;
    padding: 12px 35px;
    min-width: 340px;
    @include fs(20,30);
    @include mq(tbl) {
      @include hoverOp(.5);
      padding: 20px 35px;
    }
  }
}
@mixin hoverOp($op:0.7) {
  transition: opacity .3s $bezier;
  &:hover {
    opacity: $op;
  }
}
@mixin fs($fts, $lin: 14) {
  $rem: $fts / 10;
  $linS: $lin / $fts;
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
}
@mixin slickdots($width:8px,$height:8px) {
  text-align: center;
  li {
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0 6px;
    button {
      border: none;
      background-color: #D0D0D0;
      color: transparent;
      height: $height;
      width: $width;
      padding: 0;
      cursor: pointer;
      border-radius: 5px;
    }
    &.slick-active {
      button {
        background-color: $sub;
        outline: 1px solid $sub;
        outline-offset:2px;
      }
    }
  }
}
