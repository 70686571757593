@charset "UTF-8";
@import "variables";
@import "mixins";

.m_eyecatch {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 60px;
  }
  .inner {
    @include container;
    .image {
      text-align: center;
      img {
        border-radius: 20px;
      }
    }
  }
}
