@charset "UTF-8";
@import "variables";
@import "mixins";

.m_itemlist {
  background-color: #194375;
  padding-top: 50px;
  padding-bottom: 50px;
  @include mq(tbl) {
    padding-top: 80px;
    padding-bottom: 85px;
  }
  .inner {
    @include container($type:wide);
    .list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(auto-fit, 1fr);
      grid-gap: 20px 20px;
      @include mq(tb) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include mq(tbl) {
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 35px 20px;
      }
      li {
        a {
          display: flex;
          @include mq(tbl) {
            display: block;
            text-align: center;
            @include hoverOp;
          }
          .image {
            background-color: white;
            border-radius: 5px;
            width: 60px;
            height: 60px;
            flex: 0 1 auto;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include mq(tbl) {
              width: auto;
              height: 97px;
              padding: 10px;
            }
            img {
              width: auto;
              max-height: 100%;
            }
          }
          .title {
            padding: 4px 0 0 8px;
            color: #fff;
            @include fs(13,18);
            font-family: $font-family-sub;
            flex: 1;
            @include mq(tbl) {
              padding: 12px 0 0 0;
              @include fs(14,20);
            }
          }
        }
      }
    }
  }
}
