@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_button {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 60px;
  }
  .inner {
    @include container;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        text-align: center;
        margin: 0 10px 20px;
        @include mq(tbl) {
          margin: 0 15px 30px;
        }
        a {
          @include button($size:large);
        }
      }
    }
  }
  &.cv {
    .inner {
      ul {
        li {
          a {
            @include button($size:apply);
          }
        }
      }
    }
  }
}
