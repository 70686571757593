@charset "UTF-8";
@import "variables";
@import "mixins";

.m_logos {
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 0;
  }
  .inner {
    @include container;
    position: relative;
    @include mq(tbl) {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
    .logomark {
      text-align: right;
      img {
        width: 300px;
        opacity: .25;
        position: relative;
        right: -10px;
        @include mq(tbl) {
          width: 380px;
          right: -70px;
        }
      }
    }
    .logos {
      text-align: center;
      padding: 20px 0;
      @include mq(tbl) {
        padding: 100px 0 0;
      }
      img {
        width: 300px;
        @include mq(tbl) {
          width: 360px;
        }
      }
    }
  }
}
