@charset "UTF-8";
@import "variables";
@import "mixins";

.m_mv {
  height: 100vh;
  position: relative;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
  h1 {
    position: absolute;
    display: none;
    z-index: 2;
    @include mq(tbl) {
      display: block;
      left: 53px;
      bottom: 58px;
    }
    span {
      display: block;
      background-color: white;
      color: $color-main;
      padding: 26px 20px 26px 30px;
      @include fs(26,26);
      font-weight: $font-weight-bold;
      letter-spacing: 1px;
      &:last-child {
        transform: translateX(220px);
        display: inline-block;
      }
    }
  }
  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    img {
      width: 130px;
      @include mq(tbl) {
        width: 194px;
      }
    }
  }
  .bg {
    height: 100vh;
    position: relative;
    z-index: 1;
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
  }
  .scroll {
    position: absolute;
    display: none;
    left: 30px;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    z-index: 2;
    @include mq(tbl) {
      display: block;
      left: 30px;
      top: 50%;
      bottom: auto;
    }
    .title {
      margin-bottom: 11px;
      writing-mode: vertical-rl;
      font-feature-settings: normal;
      @include fs(11,11);
      font-family: $font-family-sub;
      color: white;
    }
    .animation {
      width: 7px;
      height: 85px;
      position: relative;
      overflow: hidden;
      @include mq(tbl) {
        height: 80px;
      }
      .bg, .line {
        height: 100%;
        width: 1px;
        background-color: white;
        position: absolute;
        left: 3px;
        .line_inner {
          animation: scroll_line 3s ease-out infinite normal;
          position: relative;
          width: 1px;
          height: 100%;
          display: block;
          background-color: white;
        }
      }
      .ball {
        animation: scroll_ball 3s ease infinite normal;
        width: 7px;
        height: 7px;
        background-color: white;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        transform-origin: center;
      }
    }
  }
}
@keyframes animation_underline_before{
  0% {
    transform:scaleX(1)
  }
  50% {
    transform:scaleX(0)
  }
  100% {
    transform:scaleX(0)
  }
}
@keyframes animation_underline_after{
  0% {
    transform:scaleX(0)
  }
  20% {
    transform:scaleX(0)
  }
  100% {
    transform:scaleX(1)
  }
}
@keyframes scroll_line {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  to {
    transform: translateY(150%);
    opacity: 0;
  }
}
@keyframes scroll_ball {
  0% {
    transform: translateY(-125px) scale(.5);
    opacity: 0;
    @include mq(tbl) {
      transform: translateY(-146px) scale(.5);
    }
  }
  40% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  to {
    transform: translateY(0) scale(0);
    opacity: 0;
  }
}
