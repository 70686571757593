@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_panel {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 60px;
  }
  + .m_general.type_panel {
    @include mq(tbl) {
      // margin-top: 0;
    }
  }
  &.set2 {
    .inner {
      @include mq(tbl) {
        gap: 60px;
      }
      .unit {
        @include mq(tbl) {
          width: ($container - 60px) / 2;
          max-width: ($container - 60px) / 2;
        }
      }
    }
  }
  &.set3 {
    .inner {
      .unit {
        @include mq(tbl) {
          width: ($container - 50px) / 3;
          max-width: ($container - 50px) / 3;
        }
      }
    }
  }
  &.set4 {
    .inner {
      @include mq(tbl) {
        gap: 20px;
      }
      .unit {
        @include mq(tbl) {
          width: ($container - 60px) / 4;
          max-width: ($container - 60px) / 4;
        }
        .title {
          @include mq(tbl) {
            margin-top: 22px;
          }
          h3 {
            @include mq(tbl) {
              @include fs(22,32);
            }
          }
        }
        .desc {
          @include mq(tbl) {
            @include fs(14,24);
          }
        }
      }
    }
  }
  .inner {
    @include container;
    @include mq(tb) {
      display: flex;
      justify-content: flex-start;
      gap: 25px;
    }
    .unit {
      margin-top: 50px;
      @include mq(tb) {
        margin-top: 0;
        max-width: 490px;
        flex: 1;
        position: relative;
      }
      &:first-of-type {
        margin-top: 0;
      }
      .photo {
        @include mq(tb) {
          display: flex;
          align-items: center;
        }
        img {
          border-radius: 5px;
          @include mq(tbl) {
            border-radius: 10px;
          }
        }
      }
      .title {
        margin-top: 22px;
        @include mq(tbl) {
          margin-top: 28px;
        }
        h3 {
          @include fs(22,32);
          color: $main-dark;
          font-weight: $font-weight-bold;
          @include mq(tbl) {
            @include fs(25,38);
          }
        }
      }
      .desc {
        @include fs(13,22);
        font-family: $font-family-main;
        margin-top: 10px;
        @include mq(tbl) {
          margin-top: 14px;
          @include fs(15,26);
        }
      }
      .button {
        margin-top: 15px;
      }
    }
  }
}
