@charset "UTF-8";
@import "variables";
@import "mixins";

.m_form {
  margin-top: 45px;
  @include mq(tbl) {
    margin-top: 75px;
  }
  .inner {
    @include container;
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    select,
    textarea {
      padding: 5px;
      width: 100%;
      box-sizing: border-box;
      @include fs(16,16);
      @include mq(tbl) {
        padding: 14px;
      }
    }
    .content01 > p{
      @include fs(14,21);
      padding-bottom: 30px;
      @include mq(tbl) {
        @include fs(16,32);
      }
    }
    .color_text01 {
      color: $red;
    }
    dl {
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      @include fs(13,18);
      @include mq(tbl) {
        @include fs(16,22);
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-left: none;
        border-right: none;
      }
      dt {
        padding: 10px;
        background: #F4F6F3;
        font-weight: $font-weight-bold;
        position: relative;
        @include mq(tbl) {
          padding: 25px 50px 25px 25px;
          width: 30%;
          border-bottom: 1px solid #ccc;
        }
      }
      dd {
        margin-bottom: 0;
        padding: 10px 10px 10px;
        border-bottom: 1px solid #ccc;
        @include mq(tbl) {
          padding: 25px;
          width: 70%;
        }
        p + p {
          margin-top: 5px;
        }
        table {
          th {
            display: block;
          }
          td {
            display: block;
            padding: 3px 0 15px;
          }
        }
        textarea {
          height: 200px;
        }
        input {
          &[type="tel"] {
            width: 120px;
            @include mq(tbl) {
              width: 180px;
            }
          }
          &.input02 {
            border: none;
            -webkit-appearance: none;
            @include button($size:large);
            font:inherit;
            @include fs(12,12);
            padding: 3px 12px 3px 10px;
            margin-left: 2px;
            height: 32px;
            @include mq(tbl) {
              @include hoverOp;
              cursor: pointer;
              height: 50px;
              margin-left: 12px;
            }
          }
        }
        select {
          width: auto;
        }
      }
    }
  }
  .btn_wrap01 {
    padding: 15px 20px;
    text-align: center;
    ul {
      display: flex;
      flex-direction: column-reverse;
      &.two_btn {
        li {
          &:first-child {
            a {
              background-color: $lightgray;
              color: $black;
              @include fs(13,24);
              @include mq(tbl) {
                @include fs(15,25);
              }
            }
          }
        }
      }
      li {
        margin-top: 20px;
        @include mq(tbl) {
          margin-top: 40px;
        }
        a {
          display: inline-block;
          background-color: $main;
          color: $white;
          @include fs(16,28);
          padding: 6px 25px 6px 25px;
          border-radius: 25px;
          text-decoration: none !important;
          font-weight: $font-weight-bold;
          min-width: 140px;
          cursor: pointer;
          @include mq(tbl) {
            @include hoverOp(.5);
            padding: 10px 35px 10px 35px;
            @include fs(18,28);
            min-width: 250px;
          }
        }
      }
    }
  }
}
