@charset "UTF-8";
@import "variables";
@import "mixins";

.m_message {
  padding-top: 85px;
  padding-bottom: 90px;
  @include container;
  @include mq(tbl) {
    padding-top: 145px;
    padding-bottom: 140px;
  }
  .inner {
    @include container(30px);
    h1 {
      text-align: center;
      @include fs(28,40);
      font-weight: $font-weight-bold;
      @include mq(tbl) {
        @include fs(36,48);
      }
    }
    .lead {
      @include sp_pc;
      @include fs(16,32);
      text-align: center;
      margin-top: 40px;
      @include mq(tbl) {
        @include fs(16,36);
        margin-top: 30px;
      }
      p {
        margin-top: 30px;
        &:first-child {
          margin-top: 0;
        }
        @include mq(tbl) {
          margin-top: 20px;
        }
      }
    }
    .photo {
      text-align: center;
      margin-top: 40px;
      img {
        width: 250px;
      }
    }
    .nav {
      margin-top: 75px;
      padding-left: 30px;
      padding-right: 30px;
      @include mq(tbl) {
        margin-top: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;
      }
      li {
        margin-top: 45px;
        @include mq(tbl) {
          margin: 45px 38px 0;
          flex: 0 1 auto;
          width: 250px;
        }
        &:first-child {
          margin-top: 0;
          @include mq(tbl) {
            margin-top: 45px;
          }
        }
        h2 {
          @include fs(24,34);
          font-weight: $font-weight-bold;
          position: relative;
          padding: 0 0 12px 0;
          @include mq(tbl) {
            @include fs(26,36);
            padding: 0 0 22px 0;
            text-align: center;
            cursor: pointer;
          }
          &:before,&:after {
            @include pd;
          }
          &:before {
            height: 1px;
            width: 100%;
            left: 0;
            bottom: 0;
            background-color: #948F7F;
          }
          &:after {
            height: 2px;
            width: 50px;
            left: 0;
            bottom: -1px;
            background-color: #194375;
            transition: width $transition-speed $bezier;
          }
          &:hover {
            &:after {
              width: 100%;
            }
          }
          a {
            color: #231815;
            position: relative;
            display: block;
            padding: 0 35px 0 0;
            @include mq(tbl) {
              padding-right: 0;
            }
            &:after {
              @include pd;
              height: 10px;
              width: 5px;
              right: 10px;
              top: 50%;
              opacity: .5;
              transform: translateY(-50%);
              background: center / contain no-repeat url(#{$imagepath}arrow_right.svg);
              @include mq(tbl) {
                content: none;
              }
            }
          }
        }
        p {
          color: #948F7F;
          margin-top: 18px;
          font-family: $font-family-sub;
          @include fs(13,22);
          @include mq(tbl) {
            margin-top: 30px;
            @include fs(13,28);
          }
        }
      }
    }
  }
}
