@charset "UTF-8";
@import "variables";
@import "mixins";

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $header-z-index;
  height: $header-height;
  background-color: $header-background-color;
  transition: transform $header-transition-speed $header-transition, background $header-transition-speed $header-transition;
  &:before {
    @include pd;
    background-color: white;
    filter: drop-shadow(0px 3px 6px rgba(0,0,0,.16));
    height: $header-height;
    width: 100%;
    z-index: 1002;
    @include mq(tbl){
      height: $header-height-pc;
    }
  }
  @include mq(tbl){
    background-color: $header-background-color-pc;
    height: $header-height-pc;
  }
  &.is-down {
    transform: $header-is-down-transform;
  }
  .toppage & {
    background-color: $header-background-color-top;
    @include mq(tbl){
      background-color: $header-background-color-top-pc;
    }
  }
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1003;
    @if $header-store {
      padding-right: $header-store-width;
    }
    @include mq(tbl){
      padding-right: 0;
      height: $header-height-pc;
    }
    #logo {
      position: relative;
      margin-right: auto;
      z-index: $header-logo-z-index;
      padding: $header-logo-padding;
      @include mq(tbl){
        padding: $header-logo-padding-pc;
      }
      a {
        display: block;
        svg, img {
          &.sp {
            width: $header-logo-width;
            transition: transform $transition-speed $bezier;
            transform-origin: left top;
            @include mq(tbl){
              display: none;
            }
          }
          &.pc {
            width: $header-logo-width-pc;
            transition: transform $transition-speed $bezier;
            transform-origin: left top;
            display: none;
            @include mq(tbl){
              display: inline-block;
            }
          }
        }
      }
    }
    #gnav {
      position: fixed;
      top: $header-height;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $gnav-z-index;
      background-color: $gnav-background-color;
      padding: $gnav-padding;
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      transform: translateX(100%);
      transition: transform $transition-speed $bezier;
      display: flex;
      flex-direction: column;
      @include mq(tbl){
        position: absolute;
        display: flex;
        top: 0;
        flex-direction: column-reverse;
        align-self: stretch;
        padding: $gnav-padding-pc;
        background-color: $gnav-background-color-pc;
        transform: translateX(0);
        overflow: visible;
      }
      &.show {
        transform: translateX(0);
      }
      .main {
        @include mq(tbl){
          display: flex;
          margin-left: auto;
          &:before {
            @include pd;
            width: 100%;
            height: $header-height-pc;
            background-color: $white;
            z-index: 10;
            bottom: 0;
            left: 0;
          }
        }
        .unit {
          margin-bottom: 20px;
          @include mq(tbl){
            margin-bottom: 0;
          }
          p {
            display: flex;
            align-items: center;
            font-weight: $font-weight-bold;
            color: $white;
            padding: 6px 15px;
            @include mq(tbl){
              width: 270px;
              height: 57px;
              padding: 0 15px;
              justify-content: center;
              @include fs(16,16);
              cursor: pointer;
              z-index: 11;
              position: relative;
            }
            span {
              a {
                color: $white;
              }
            }
            &:before {
              @include pd;
              position: relative;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              margin-right: 10px;
            }
            &.customer {
              background-color: $main-light;
              @include mq(tbl){
                border-radius: 10px 0 0 0;
              }
              &:before {
                background-image: url(#{$imagepath}patient.svg);
                width: 19px;
                height: 28px;
              }
            }
            &.hospital {
              background-color: $sub;
              &:before {
                background-image: url(#{$imagepath}stethoscope.svg);
                width: 29px;
                height: 28px;
              }
              + .float ul li a span::before {
                background-image: url(#{$imagepath}arrow_right_sub.svg);
              }
            }
            &.about {
              background-color: $third;
              &:before {
                background-image: url(#{$imagepath}medicine.svg);
                width: 27px;
                height: 29px;
              }
              + .float ul li a span::before {
                background-image: url(#{$imagepath}arrow_right_third.svg);
              }
            }
          }
          .float {
            @include mq(tbl){
              position: absolute;
              width: 100%;
              top: $header-height-pc;
              left: 0;
              right: 0;
              padding: 25px 0;
              z-index: 9;
              transform: translateY(-100%);
              transition: transform $transition-speed $bezier;
              overflow: hidden;
              &.customer {
                background-color: $main-light;
              }
              &.hospital {
                background-color: $sub;
              }
              &.about {
                background-color: $third;
              }
              &.active {
                transform: translateY(0);
              }
            }
            ul {
              @include mq(tbl) {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
              }
              li {
                border-bottom: 1px solid #DBDBDB;
                @include mq(tbl){
                  border-bottom: none;
                }
                &.top {
                  @include mq(tbl) {
                    display: none;
                  }
                }
                a {
                  display: block;
                  padding: $gnav-main-list-padding;
                  font-size: $gnav-main-list-font-size;
                  line-height: $gnav-main-list-line-height;
                  color: $gnav-main-list-font-color;
                  @include mq(spl) {
                    padding: $gnav-main-list-padding-spl;
                    font-size: $gnav-main-list-font-size-spl;
                    line-height: $gnav-main-list-line-height-spl;
                    color: $gnav-main-list-font-color-spl;
                  }
                  @include mq(tbl) {
                    padding: $gnav-main-list-padding-pc;
                    font-size: $gnav-main-list-font-size-pc;
                    line-height: $gnav-main-list-line-height-pc;
                    color: $gnav-main-list-font-color-pc;
                    @include hoverOp($gnav-main-list-current-opacity);
                    font-weight: $font-weight-bold;
                    white-space: nowrap;
                  }
                  .toppage & {
                    color: $gnav-main-list-font-color-top;
                    @include mq(spl) {
                      color: $gnav-main-list-font-color-top-spl;
                    }
                    @include mq(tbl) {
                      color: $gnav-main-list-font-color-top-pc;
                    }
                  }
                  span {
                    position: relative;
                    padding-left: 25px;
                    @include mq(tbl){
                      padding: 0;
                    }
                    &:before {
                      @include pd;
                      background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
                      width: 15px;
                      height: 15px;
                      top: 50%;
                      transform: translateY(-50%);
                      @include mq(tbl){
                        content: none;
                      }
                    }
                  }
                  &.current {
                    span {
                      color: $black;
                      @include mq(tbl) {
                        position: relative;
                        opacity: 1;
                        &:after {
                          @include pd;
                          height: 1px;
                          width: 100%;
                          background-color: $gnav-main-list-current-underline-color;
                          bottom: $gnav-main-list-current-underline-position;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .sub {
        @include mq(spl) {
          margin-left: auto;
        }
        @include mq(tbl){
          position: relative;
          z-index: 11;
          display: flex;
          align-items: center;
          padding-bottom: 13px;
          margin-left: auto;
        }
        .sns {
          margin: $header-sns-margin;
          display: flex;
          justify-content: center;
          @include mq(spl) {
            margin: $header-sns-margin-spl;
          }
          @include mq(tbl){
            margin: 0;
          }
          li {
            margin-right: $header-sns-list-margin-right;
            &:last-child {
              margin-right: 0;
            }
            @include mq(tbl){
              margin-right: 15px;
            }
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $header-sns-icon-background-color;
              width: $header-sns-icon-width;
              height: $header-sns-icon-height;
              border-radius: $header-sns-icon-radius;
              @include mq(spl) {
                width: $header-sns-icon-width-spl;
                height: $header-sns-icon-height-spl;
              }
              @include mq(tbl){
                width: 20px;
                height: 20px;
                @include hoverOp;
              }
              svg {
                width: $header-sns-icon-image-width;
                fill: $header-sns-icon-color;
                @include mq(spl) {
                  width: $header-sns-icon-image-width-spl;
                }
                @include mq(tbl){
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
        .util {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          @include mq(tbl){
            margin-top: 0;
            margin-left: 27px;
          }
          li {
            @include fs(12,12);
            font-weight: $font-weight-bold;
            border-left: 1px solid #C1C1C1;
            padding: 0 13px;
            @include mq(tbl){
              @include fs(14,14);
              padding: 0 27px;
            }
            &:first-child {
              border: none;
              @include mq(tbl){
                border-left: 1px solid #C1C1C1;
              }
            }
            a {
              @include mq(tbl){
                @include hoverOp;
              }
              &.contact {
                position: relative;
                padding-left: 25px;
                &:before {
                  @include pd;
                  background: center / contain no-repeat url(#{$imagepath}mail.svg);
                  width: 18px;
                  height: 14px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
    }
    #menuButton {
      position: absolute;
      right: 0;
      top: 0;
      width: $header-height;
      height: $header-height;
      cursor: pointer;
      z-index: $menu-z-index;
      background-color: $menu-backgound-color;
      @include mq(tbl){
        display: none;
      }
      span {
        background-color: $menu-bar-backgound-color;
        width: $menu-bar-width;
        height: $menu-bar-height;
        border-radius: $menu-bar-border-radius;
        position: absolute;
        left: ($header-height - $menu-bar-width) / 2;
        &.top {
          top: $menu-bar-top-pos;
          transition: transform $transition-speed-fast $bezier, background $transition-speed-fast $bezier;
        }
        &.middle, &.back {
          top: $menu-bar-middle-pos;
          transform-origin: center;
          transition: transform $transition-speed-fast $bezier, background $transition-speed-fast $bezier;
        }
        &.bottom {
          top: $menu-bar-bottom-pos;
          transition: transform $transition-speed-fast $bezier, background $transition-speed-fast $bezier;
        }
      }
      &.show {
        span {
          &.top {
            transform: translateX(50%);
            background-color: transparent;
          }
          &.bottom {
            transform: translateX(-50%);
            background-color: transparent;
          }
          &.middle {
            transform: rotate(45deg) scaleX(1);
            transition: transform $transition-speed-fast $bezier $transition-speed-fast;
          }
          &.back {
            transform: rotate(-45deg) scaleX(1);
          }
        }
      }
    }
  }
}
