@charset "UTF-8";
@import "variables";
@import "mixins";

.m_contact {
  margin-top: 60px;
  .inner {
    @include container;
    dl {
      font-family: $font-family-sub;
      @include mq(tbl){
        padding-left: 40px;
        padding-right: 40px;
      }
      dt {
        margin-top: 20px;
        @include fs(15,28);
        @include mq(tbl){
          @include fs(16,34);
          margin-top: 38px;
        }
        span {
          @include fs(10,28);
          @include mq(tbl){
            @include fs(12,34);
          }
        }
      }
      dd {
        margin-top: 13px;
        input[type="text"],input[type="email"],input[type="tel"], textarea {
          font: inherit;
          background-color: white;
          @include fs(16,22);
          padding: 0 10px;
          line-height: 40px;
          height: 40px;
          width: 100%;
          border-radius: 0;
          border: 1px solid transparent;
          transition: border .5s $bezier, background-color .5s $bezier;
          @include mq(tbl) {
            padding: 0 15px;
            line-height: 60px;
            height: 60px;
            padding: 0 20px;
          }
          &:focus {
            border-color: #D8CEBF;
            background-color: $white;
          }
          @include mq(tbl) {
            &:hover {
              border-color: #D8CEBF;
            }
          }
          &.wpcf7-not-valid {
            border-color: $red;
          }
        }
        input[type="tel"] {
          @include mq(tbl) {
            width: 50%;
          }
        }
        textarea {
          height: 140px;
          max-width: 100%;
          padding: 10px;
          @include fs(16,20);
          @include mq(tbl) {
            height: 275px;
            padding: 15px;
          }
        }
        .wpcf7-not-valid-tip {
          color: $red;
          @include fs(14,22);
          display: block;
          margin: 5px 0 0;
          padding-bottom: 10px;
        }
        .radio {
          display: flex;
          flex-wrap: wrap;
          @include mq(tbl){
            margin-top: 28px;
          }
          label {
            display: flex;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            margin-bottom: 12px;
            margin-right: 30px;
            @include mq(tbl){
              margin-right: 35px;
            }
            input {
              position: absolute;
              left: -9999px;
              &:checked + span {
                &:before {
                  background-color: white;
                  box-shadow: inset 0 0 0 3px $color-main;
                  @include mq(tbl){
                    box-shadow: inset 0 0 0 6px $color-main;
                  }
                }
              }
            }
            span {
              display: flex;
              align-items: center;
              &:hover {
                &:before {
                  background-color: white;
                  box-shadow: inset 0 0 0 1px $color-main;
                }
              }
              &:before {
                display: flex;
                flex-shrink: 0;
                content: "";
                background-color: #E7DFD2;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                margin-right: 0.375em;
                //transition: box-shadow .5s $bezier, background-color .5s $bezier;
                box-shadow: inset 0 0 0 3px #E7DFD2;
                @include mq(tbl){
                  width: 22px;
                  height: 22px;
                  box-shadow: inset 0 0 0 6px #E7DFD2;
                }
              }
            }
          }
        }
        .select_list {
          display: flex;
          align-items: center;
          li {
            margin-right: 8px;
            @include mq(tbl) {
              margin-right: 15px;
            }
            .wpcf7-form-control-wrap {
              position: relative;
              display: flex;
              width: 115px;
              height: 40px;
              line-height: 40px;
              background: white;
              overflow: hidden;
              border: 1px solid transparent;
              transition: border .5s $bezier;
              @include mq(tb) {
                width: 135px;
              }
              @include mq(tbl) {
                line-height: 60px;
                height: 60px;
                width: 215px;
              }
              &:after {
                content: '';
                position: absolute;
                top: 50%;
                margin-top: -3px;
                right: 12px;
                display: block;
                cursor: pointer;
                pointer-events: none;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 4px 0 4px;
                border-color: $color-main transparent transparent transparent;
                @include mq(tb) {
                  right: 20px;
                }
              }
              &:hover {
                @include mq(tbl){
                  border-color: #D8CEBF;
                }
              }
              select {
                appearance: none;
                outline: 0;
                box-shadow: none;
                border: 0 !important;
                background: white;
                background-image: none;
                flex: 1;
                padding: 0 5px;
                font: inherit;
                cursor: pointer;
                @include mq(tb) {
                  padding: 0 15px;
                }
                @include mq(tbl) {
                  padding: 0 25px;
                }
                &::-ms-expand {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .button {
      text-align: center;
      margin: 35px 0 0;
      @include mq(tbl) {
        margin-top: 70px;
        padding-left: 40px;
        padding-right: 40px;
      }
      button {
        background-color: $color-main;
        font: inherit;
        font-family: $font-family-sub;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;
        @include fs(14,14);
        color: white;
        text-align: center;
        width: 100%;
        padding: 16px 14px;
        position: relative;
        @include mq(tbl) {
          transition: background-color .4s $bezier;
          padding: 24px 15px;
          @include fs(18,18);
          &:hover {
            background-color: #BCA37A;
          }
        }
      }
    }
  }
}
.m_contact_thanks {
  padding-bottom: 55px;
  .lead {
    @include fs(13,30);
    text-align: center;
    @include mq(tbl){
      @include fs(14,38);
    }
  }
  .email {
    @include fs(13,13);
    text-align: center;
    margin-top: 40px;
    padding-top: 55px;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 50px;
      height: 1px;
      transform: rotate(-19deg) translateX(-50%);
      background-color: $black;
      position: absolute;
      top: 0;
      left: 50%;
    }
    @include mq(tbl){
      @include fs(14,14);
    }
    a {
      display: inline-block;
      position: relative;
      @include mq(tbl) {
        padding: 0 0 5px;
        &:before {
          transition: width .9s cubic-bezier(.29,.55,0,.97);
          content: '';
          bottom: -1px;
          left: 0;
          width: 0;
          height: 1px;
          display: block;
          position: absolute;
          background-color: $black;
        }
        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }
}
div.wpcf7-response-output {
  text-align: center;
  border-color: transparent !important;
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 70px;
  }
}
div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing {
  color: $red;
}
div.wpcf7 .ajax-loader {
    display: none !important;
}
span.wpcf7-list-item {
  margin: 0 !important;
}
