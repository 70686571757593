@charset "UTF-8";
@import "variables";
@import "mixins";

footer {
  background-color: #f5f5f5;
  margin-top: 65px;
  @include mq(tbl) {
    margin-top: 135px;
  }
  .inner {
    @include container($type:wide);
    .footnav {
      padding-top: 45px;
      @include mq(tbl) {
        padding-top: 75px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, auto);
        grid-gap: 60px 15px;
      }
      .unit {
        position: relative;
        &:last-child {
          border-bottom: 1px solid #CCCBCA;
          @include mq(tbl) {
            border-bottom: none;
          }
        }
        h2 {
          border-top: 1px solid #CCCBCA;
          @include fs(13,21);
          padding: 13px 10px;
          position: relative;
          font-weight: $font-weight-bold;
          @include mq(tbl) {
            @include fs(16,26);
            padding: 0;
            pointer-events: none;
            border-top: none;
          }
          &:after, &:before {
            @include pd;
            right: 8px;
            top: 50%;
            background-color: #8E8E8E;
            width: 13px;
            height: 1px;
            @include mq(tbl) {
              display: none;
            }
          }
          &:after {
            width: 1px;
            height: 13px;
            right: 14px;
            margin-top: -6px;
            transition: transform $bezier $transition-speed;
          }
          &.active {
            &:after {
              transform: scaleY(0);
            }
          }
        }
        ul {
          display: none;
          @include fs(13,21);
          padding-top: 4px;
          padding-bottom: 15px;
          @include mq(tbl) {
            display: block !important;
            @include fs(14,21);
            padding: 20px 0 0 0;
          }
          + h2 {
            @include mq(tbl) {
              margin-top: 40px;
            }
          }
          li {
            a {
              display: block;
              padding: 8px 0 8px 30px;
              position: relative;
              color: $black;
              @include mq(tbl) {
                padding: 7px 0 7px 13px;
                @include hoverOp(.3);
              }
              &:before {
                @include pd;
                height: 1px;
                width: 5px;
                background-color: $black;
                top: 18px;
                left: 20px;
                @include mq(tbl) {
                  left: 0px;
                }
              }
            }
          }
        }
      }
    }
    .footbanner {
      padding-top: 40px;
      @include mq(tbl) {
        padding-top: 50px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      a {
        background-color: white;
        padding: 15px 25px;
        display: grid;
        grid: "icon text" auto / auto 1fr;
        grid-gap: 16px;
        align-content: center;
        border-radius: 6px;
        margin-top: 20px;
        min-height: 60px;
        @include mq(tbl) {
          margin: 0 15px 30px;
          @include hoverOp(.5);
        }
        &:first-child {
          margin-top: 0;
        }
        i {
          grid-area: icon;
          display: flex;
          align-items: center;
          img {
            max-width: 70px;
            max-height: 30px;
          }
        }
        p {
          grid-area: text;
          display: flex;
          align-items: center;
          color: #231815;
          @include fs(14,20);
          font-family: $font-family-sub;
        }
      }
    }
    .footinfo {
      padding-top: 30px;
      @include mq(tbl) {
        padding-top: 60px;
        display: flex;
        justify-content: space-between;
      }
      .corpinfo {
        p {
          text-align: center;
          @include mq(tbl) {
            text-align: left;
          }
          img {
            width: 155px;
            @include mq(tbl) {
              width: 220px;
            }
          }
        }
        .contact {
          margin-top: 22px;
          text-align: center;
          @include fs(12,23);
          @include mq(tbl) {
            text-align: left;
            margin-top: 25px;
            @include fs(13,28);
          }
          address {
            .zip {
              display: block;
              @include mq(tbl) {
                display: inline;
                margin-right: 1em;
              }
            }
          }
          ul {
            display: flex;
            justify-content: center;
            @include mq(tbl) {
              justify-content: flex-start;
            }
            li {
              margin-right: 1em;
              &:after {
                content: '|';
                padding-left: 1em;
              }
              &:last-child {
                margin-right: 0;
                &:after {
                  content: none;
                }
              }
            }
          }
        }
      }
      .sns {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        @include mq(tbl) {
          margin-top: 0;
        }
        li {
          margin-right: 30px;
          &:last-child {
            margin-right: 0;
          }
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            @include mq(tbl) {
              @include hoverOp(.3);
            }
            svg {
              width: 30px;
              fill: #948F7F;
            }
          }
        }
      }
    }
  }
  .copyright {
    margin-top: 35px;
    background-color: #5c5c5c;
    color: $white;
    @include container($type:wide);
    padding-top: 25px;
    padding-bottom: 30px;
    @include mq(tbl) {
      background-color: transparent;
      margin-top: 40px;
      padding-top: 0;
      padding-bottom: 80px;
      color: #6A6A6A;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    ul {
      @include mq(tbl) {
        display: flex;
      }
      li {
        margin-top: 14px;
        @include mq(tbl) {
          margin-top: 0;
          margin-right: 35px;
          &:last-child {
            margin-right: 0;
          }
        }
        &:first-child {
          margin-top: 0;
        }
        a {
          display: block;
          text-align: center;
          color: $white;
          @include fs(12,18);
          font-weight: $font-weight-bold;
          @include mq(tbl) {
            @include hoverOp(.3);
            color: #6A6A6A;
          }
        }
      }
    }
    p {
      margin-top: 20px;
      text-align: center;
      @include fs(10,20);
      @include mq(tbl) {
        margin-top: 0;
        text-align: left;
        @include fs(12,20);
      }
      span {
        display: block;
        @include mq(tbl) {
          display: inline;
          margin-left: 1em;
        }
      }
    }
  }
}

#pagetop {
  position: fixed;
  bottom: 24px;
  right: 18px;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
  transition: opacity .5s $bezier;
  @include mq(tbl) {
    bottom: 85px;
    right: 30px;
  }
  a {
    transition: opacity .5s $bezier;
    @include mq(tbl) {
      width: 50px;
      height: 50px;
      &:hover {
        opacity: .5;
      }
    }
    img {
      width: 40px;
      height: 40px;
      @include mq(tbl) {
        width: 50px;
        height: 50px;
      }
    }
  }
  &.on {
    opacity: 1;
  }
}
