@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_heading {
  margin-top: 50px;
  @include mq(tbl) {
    margin-top: 70px;
  }
  &.large {
    .inner {
      @include container;
      .heading {
        @include title;
      }
    }
  }
  &.medium {
    .inner {
      @include container;
      .heading {
        @include fs(22,32);
        font-weight: $font-weight-bold;
        position: relative;
        padding-bottom: 8px;
        border-bottom: 1px solid $main;
        @include mq(tbl) {
          @include fs(24,34);
          padding-bottom: 12px;
        }
      }
    }
  }
  .anchor + & {
    // margin-top: 20px;
    @include mq(tbl) {
      // margin-top: 45px;
    }
  }
}
