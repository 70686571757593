@charset "UTF-8";
@import "variables";
@import "mixins";

.m_stores {
  padding-top: 60px;
  @include mq(tbl) {
    padding-top: 85px;
  }
  .list {
    @include container;
    margin-top: 30px;
    @include mq(tbl) {
      margin-top: 40px;
      display: grid;
       grid-template-columns: repeat(3, 1fr);
       grid-template-rows: repeat(auto-fit, 1fr);
       grid-gap: 40px 25px;
    }
    .unit {
      margin-top: 50px;
      @include mq(tbl) {
        margin-top: 0;
      }
      &:first-of-type {
        margin-top: 0;
      }
      .title {
        margin-top: 20px;
        p {
          @include fs(12,18);
          color: #948F7F;
          margin-bottom: 5px;
        }
        h3 {
          @include fs(20,30);
        }
      }
      address {
        @include fs(13,22);
        font-family: $font-family-sub;
        color: #948F7F;
        margin-top: 10px;
      }
      .button {
        margin-top: 20px;
      }
    }
  }
  .banner {
    @include container;
    margin-top: 80px;
    li {
      margin-top: 40px;
      @include mq(tbl) {
        margin-top: 0;
      }
      &:first-child {
        margin-top: 0;
      }
      img {
        filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, .3));
        @include sp_pc;
        @include mq(tbl) {
          @include hoverOp(.7);
        }
      }
    }
  }
}
