@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_column {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 60px;
  }
  .container {
    @include container;
    .inner {
      background-color: #DFEBD9;
      padding: 20px 20px 25px;
      gap: 0;
      border-radius: 10px;
      @include mq(tbl) {
        padding: 35px 42px;
        display: grid;
        grid-template-columns: 37% 63%;
        grid-template-rows: auto auto 1fr;
      }
      &.textonly {
        display: block;
      }
      h3 {
        padding-bottom: 12px;
        @include fs(22,32);
        color: $main-dark;
        font-weight: $font-weight-bold;
        @include mq(tbl) {
          @include fs(25,38);
          grid-column: 2 / 3;
          grid-row: 1 / 2;
          padding-bottom: 16px;
        }
      }
      .photo {
        padding-bottom: 10px;
        @include mq(tbl) {
          grid-column: 1 / 2;
          grid-row: 1 / 4;
          padding-right: 40px;
          padding-bottom: 0;
        }
        .child {
          margin-top: 20px;
          text-align: center;
          @include mq(tbl) {
            &:first-of-type {
              margin-top: 0;
            }
          }
          .image_sp {
            padding-bottom: 0;
            @include mq(tbl) {
              display: none;
            }
            img {
              max-width: 350px;
            }
          }
          .image_pc {
            display: none;
            padding-bottom: 0;
            @include mq(tbl) {
              display: inline;
            }
          }
          .caption {
            @include fs(10,14);
            margin-top: 10px;
            text-align: left;
            font-weight: $font-weight-bold;
            color: rgba($body-text-color, 0.75);
            @include mq(tbl) {
              @include fs(12,18);
            }
            &.left {
              text-align: left;
            }
            &.center {
              text-align: center;
            }
            &.right {
              text-align: right;
            }
          }
        }
      }
      h4 {
        @include fs(15,24);
        font-weight: bold;
        @include mq(tbl) {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
          @include fs(18,32);
        }
      }
      .text {
        padding-top: 12px;
        @include mq(tbl) {
          grid-column: 2 / 3;
          grid-row: 3 / 4;
        }
      }
    }
  }
  &.reverse {
    .container {
      .inner {
        @include mq(tbl) {
          grid-template-columns: 68% 32%;
          grid-template-rows: auto auto 1fr;
        }
        h3 {
          @include mq(tbl) {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
          }
        }
        .photo {
          @include mq(tbl) {
            grid-column: 2 / 3;
            grid-row: 1 / 4;
            padding-right: 0;
            padding-left: 40px;
          }
        }
        h4 {
          @include mq(tbl) {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
          }
        }
        .text {
          @include mq(tbl) {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
          }
        }
      }
    }
  }
  &.vertical {
    .container {
      .inner {
        @include mq(tbl) {
          display: block;
        }
        .photo {
          @include mq(tbl) {
            padding: 40px 0 20px;
            display: flex;
            justify-content: center;
          }
          .child {
            @include mq(tbl) {
              width: 100%;
              margin-right: 30px;
              margin-top: 0;
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  &.vertical_reverse {
    .container {
      .inner {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto;
        h3 {
          grid-row: 1 / 2;
          grid-column: auto;
        }
        h4 {
          grid-row: 2 / 3;
          grid-column: auto;
        }
        .photo {
          grid-row: 4 / 5;
          grid-column: auto;
          padding-bottom: 0;
          margin-top: 10px;
          @include mq(tbl) {
            padding-right: 0;
            padding-left: 0;
            padding-bottom: 10px;
            padding-top: 0;
            display: flex;
            justify-content: center;
          }
          .child {
            @include mq(tbl) {
              margin-top: 35px;
              width: 100%;
              margin-right: 30px;
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
        .text {
          grid-row: 3 / 4;
          grid-column: auto;
        }
      }
    }
  }
}
