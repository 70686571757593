@charset "UTF-8";
@import "variables";
@import "mixins";

.m_localnav {
  padding: 15px 0 0;
  @include mq(tbl) {
    padding: 45px 0 0;
  }
  .inner {
    .overflow {
      overflow: auto;
      padding: 0 15px 10px;
      @include mq(tbl) {
        overflow: visible;
        padding: 0;
      }
      ul {
        display: flex;
        justify-content: flex-start;
        @include mq(tbl) {
          justify-content: center;
          flex-wrap: wrap;
        }
        li {
          @include fs(11,18);
          white-space: nowrap;
          padding-right: 10px;
          font-weight: $font-weight-bold;
          @include mq(tbl) {
            @include fs(13,21);
            margin-bottom: 10px;
            &:last-child {
              padding-right: 0;
            }
          }
          &.active,&.current_page_item {
            a {
              color: $white;
              background-color: $main;
              border-color: $main;
              &:before {
                background: center / contain no-repeat url(#{$imagepath}arrow_right_white.svg);
              }
            }
          }
          a {
            display: block;
            position: relative;
            transition: color $transition-speed $bezier, opacity $transition-speed $bezier;
            background-color: $white;
            border-radius: 25px;
            border: 1px solid #CCCCCC;
            padding: 8px 15px 8px 40px;
            color: #909090;
            letter-spacing: 0;
            @include mq(tbl) {
              border-radius: 35px;
              padding: 8px 25px 6px 35px;
              @include hoverOp;
            }
            &:before,&:after {
              @include pd;
            }
            &:before {
              @include pd;
              background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
              width: 15px;
              height: 15px;
              top: 50%;
              left: 12px;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}
