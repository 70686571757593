@charset "UTF-8";
@import "variables";
@import "mixins";

.m_archivenav {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 80px;
  }
  .inner {
    @include container;
    .overflow {
      overflow: auto;
      padding: 0 0 10px;
      @include mq(tbl) {
        overflow: visible;
        padding: 0;
      }
      ul {
        display: flex;
        justify-content: center;
        position: relative;
        &:before {
          @include pd;
          background-color: $black;
          height: 1px;
          bottom: -1px;
          width: 100%;
        }
        @include mq(tbl) {
          flex-wrap: wrap;
        }
        li {
          @include fs(11,18);
          white-space: nowrap;
          margin-left: -1px;
          position: relative;
          padding: 0 2px 0;
          &:first-child {
            margin-left: 0;
          }
          @include mq(tbl) {
            @include fs(16,16);
          }
          &.active,&.current_page_item {
            z-index: 2;
            a {
              color: $black;
              background-color: $white;
              border-color: #414141;
              &:before {
                background-color: $white;
                left: 0;
                right: 0;
              }
            }
          }
          a {
            display: block;
            position: relative;
            background-color: #F5F5F5;
            border: 1px solid transparent;
            border-bottom: none;
            padding: 11px 20px;
            border-radius: 5px 5px 0 0;
            color: #A0A1A1;
            letter-spacing: 0;
            font-weight: $font-weight-bold;
            transition: color $transition-speed $bezier;
            @include mq(tbl) {
              padding: 16px 36px;
              border-radius: 10px 10px 0 0;
            }
            &:before {
              @include pd;
              height: 1px;
              bottom: -1px;
              left: -1px;
              right: -1px;
            }
            &:hover {
              @include mq(tbl) {
                color: $black;
              }
            }
          }
        }
      }
    }
  }
}
