@charset "UTF-8";
@import "variables";
@import "mixins";

.m_localnav_bottom {
  margin-top: 60px;
  background-color: #F5F5F5;
  @include mq(tbl) {
    margin-top: 120px;
  }
  .inner {
    @include container;
    padding-top: 20px;
    padding-bottom: 30px;
    @include mq(tbl) {
      padding-top: 50px;
      padding-bottom: 90px;
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #CCCCCC;
      padding-bottom: 20px;
      @include mq(tbl) {
        display: block;
        position: relative;
        text-align: center;
        padding-bottom: 40px;
      }
      h2 {
        font-weight: $font-weight-bold;
        @include fs(18,26);
        padding-right: 25px;
        @include mq(tbl) {
          @include fs(26,34);
          letter-spacing: 3px;
        }
      }
      .all {
        @include fs(12,21);
        @include mq(tbl) {
          @include fs(16,16);
          position: absolute;
          right: 0;
          top: 15px;
        }
        a {
          position: relative;
          padding-left: 17px;
          &:before {
            @include pd;
          }
          &:before {
            @include pd;
            background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
            width: 11px;
            height: 11px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
      }
    }
    ul {
      @include mq(tbl) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
      }
      li {
        @include mq(tbl) {
          margin: 0 5% 0 0;
          width: 30%;
          &:nth-child(3n){
            margin-right: 0;
          }
        }
        &.active {
          a {
            @include mq(tbl) {
              border-bottom: 3px solid $main;
            }
            span {
              color: $main-dark;
            }
          }
        }
        a {
          border-bottom: 1px solid #ccc;
          padding: 13px 0;
          display: block;
          @include mq(tbl) {
            padding: 40px 0 16px;
          }
          &:hover {
            @include mq(tbl) {
              border-bottom-color: $main;
              color: $main;
            }
          }
          i {
            flex: 0 1 auto;
            width: 60px;
            height: 60px;
            background: white center / 50% no-repeat url(/images/logomark.svg);
            margin-right: 15px;
            @include mq(tbl) {
              margin-right: 25px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          span {
            display: block;
            position: relative;
            padding-left: 25px;
            font-weight: $font-weight-bold;
            @include fs(13,21);
            @include mq(tbl) {
              @include fs(16,26);
            }
            &:before,&:after {
              @include pd;
            }
            &:before {
              @include pd;
              background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
              width: 16px;
              height: 16px;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
        }
        &.active,&.current_page_item {
          a {

          }
        }
      }
    }
  }
}
