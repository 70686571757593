@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_icon {
  margin-top: 40px;
  padding-bottom: 20px;
  @include mq(tbl) {
    margin-top: 80px;
    padding-bottom: 20px;
  }
  &.set3, &.set4 {
    .inner {
      @include mq(tb) {
        gap: 4%;
      }
      .unit {
        @include mq(tb) {
          width: 22%;
        }
        .icon {
          @include mq(tb) {
            display: block;
            padding: 0 0;
            min-height: auto;
          }
          .image {
            @include mq(tb) {
              height: 150px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            @include mq(tbl) {
              height: 150px;
            }
          }
          .data {
            @include mq(tb) {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  .inner {
    @include container;
    @include mq(tb) {
      display: flex;
      justify-content: center;
      gap: 6%;
    }
    .unit {
      margin-top: 50px;
      text-align: center;
      @include mq(tb) {
        margin-top: 0;
        width: 47%;
        display: flex;
        flex-direction: column;
      }
      &:first-of-type {
        margin-top: 0;
      }
      h3 {
        @include fs(14,21);
        border-bottom: 1px solid #6F6F6F;
        padding-bottom: 10px;
        font-weight: $font-weight-bold;
        @include mq(tbl) {
          @include fs(16,24);
        }
      }
      .icon {
        margin-top: 30px;
        @include mq(tbl) {
          margin-top: 50px;
          display: flex;
          align-items: center;
          padding: 0 30px;
          min-height: 150px;
        }
        .image {
          @include mq(tb) {
            flex: 1;
          }
          img {
            max-width: 150px;
            max-height: 150px;
          }
        }
        .data {
          margin-top: 20px;
          @include mq(tbl) {
            margin-top: 0;
            flex: 1;
          }
          em {
            @include fs(70,70);
            display: block;
            color: $main-dark;
            @include mq(tb) {
              @include fs(60,60);
            }
            @include mq(tbl) {
              @include fs(70,70);
            }
          }
          span {
            @include fs(20,20);
            display: block;
            margin-top: 5px;
            font-weight: $font-weight-bold;
            @include mq(tbl) {
              @include fs(24,24);
            }
          }
        }
      }
      .note {
        background-color: #F2F2F2;
        border-radius: 10px;
        padding: 15px;
        @include fs(11,20);
        margin-top: 15px;
        font-weight: $font-weight-bold;
        @include mq(tbl) {
          margin-top: 30px;
          @include fs(13,22);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
