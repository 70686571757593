@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_catalog {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  .inner {
    @include container;
    .list {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(auto-fit, 1fr);
      grid-gap: 40px 25px;
      @include mq(tbl) {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 60px 28px;
      }
      .unit {
        .main {
          /*display: flex;
          justify-content: space-between;
          height: 100%;
          flex-direction: column;*/
          .image {
            a {
              display: block;
              @include mq(tbl) {
                @include hoverOp;
              }
            }
            img {
              border-radius: 0;
            }
          }
          .desc {
            text-align: center;
            .subtitle {
              padding-bottom: 5px;
              @include fs(12,18);
              color: #666;
              @include mq(tbl) {
                @include fs(13,21);
                padding-bottom: 10px;
              }
            }
            .title {
              @include fs(16,24);
              margin-top: 12px;
              @include mq(tbl) {
                @include fs(18,24);
                @include hoverOp;
                margin-top: 20px;
              }
              a {
                color: $main-dark;
                font-weight: $font-weight-bold;
                padding-left: 22px;
                position: relative;
                text-decoration: none;
                word-break: break-all;
                &:before {
                  @include pd;
                  background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
                  width: 15px;
                  height: 15px;
                  top: 10px;
                  left: 0;
                  transform: translateY(-50%);
                  .win.chrome & {
                    top: 14px;
                  }
                }
              }
            }
            .date {
              margin-top: 8px;
              @include fs(10,14);
              font-weight: $font-weight-bold;
              @include mq(tbl) {
                @include fs(12,18);
                margin-top: 10px;
              }
            }
            .format {
              @include fs(11,18);
              margin-top: 12px;
              font-weight: $font-weight-bold;
              letter-spacing: 0;
              @include mq(tbl) {
                @include fs(12,18);
                margin-top: 14px;
              }
              img {
                width: 20px;
                height: 22px;
                object-fit: contain;
                margin-right: 8px;
              }
              span {
                text-transform: uppercase;
                letter-spacing: 0;
              }
              a {
                color: $main-dark;
                text-decoration: none;
                @include mq(tbl) {
                  @include hoverOp;
                }
              }
            }
          }
        }
        .summary {
          @include fs(12,20);
          margin-top: 12px;
          @include mq(tbl) {
            @include fs(13,22);
            margin-top: 16px;
          }
        }
      }
    }
    .more {
      margin-top: 40px;
      text-align: center;
    }
  }
}
