@charset "UTF-8";
@import "variables";
@import "mixins";

.m_newslist {
  margin-top: 25px;
  @include mq(tbl) {
    margin-top: 40px;
  }
  .inner {
    @include container;
    ul {
      border-top: 1px solid #E2E2E2;
      li {
        border-bottom: 1px solid #E2E2E2;
        a {
          display: block;
          padding: 13px 5px;
          @include mq(tbl) {
            display: flex;
            align-items: flex-start;
            padding: 24px 3px;
            position: relative;
            @include hoverOp(.5);
          }
          .date {
            @include fs(11,11);
            font-weight: $font-weight-bold;
            @include mq(tbl) {
              @include fs(12,12);
              padding-top: 5px;
            }
          }
          .cat {
            background-color: $white;
            border-radius: 4px;
            color: $black;
            font-weight: $font-weight-bold;
            border: 1px solid $main;
            @include fs(10,10);
            display: inline-block;
            padding: 4px 10px;
            margin-left: 5px;
            min-width: 100px;
            @include mq(tbl) {
              position: absolute;
              @include fs(12,12);
              min-width: 120px;
              margin: 0;
              left: 100px;
              padding: 6px 10px 7px;
            }
          }
          h2 {
            margin-top: 7px;
            @include fs(14,20);
            @include mq(tbl) {
              margin: 0 0 0 235px;
              @include fs(16,28);
            }
            /*
            &:before {
              @include pd;
              background: url(#{$imagepath}arrow_right_white.svg);
              width: 16px;
              height: 16px;
              top: 8px;
              left: 0;
              transform: translateY(-50%);
              @include mq(tbl) {
                top: 11px;
              }
            }
            */
          }
        }
      }
    }
  }
}
