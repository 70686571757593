@charset "UTF-8";
@import "variables";
@import "mixins";

.p_button {
  @include button;
}
.p_title {
  .title {
    @include title;
  }
}
