@charset "UTF-8";
@import "variables";
@import "mixins";

.m_indexlist {
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 65px;
  }
  .inner {
    @include container;
    @include mq(tbl) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(auto-fit, 1fr);
      grid-gap: 60px 60px;
    }
    .unit {
      margin-top: 30px;
      @include mq(tbl) {
        margin-top: 0;
      }
      &:first-of-type {
        margin-top: 0;
      }
      h3 {
        @include fs(18,28);
        font-weight: $font-weight-bold;
        border-bottom: 1px solid $main;
        padding-bottom: 10px;
        @include mq(tbl) {
          @include fs(24,36);
          padding-bottom: 14px;
        }
      }
      ul {
        margin-top: 15px;
        column-count: 2;
        column-gap: 20px;
        @include mq(tbl) {
          padding: 0 20px;
          margin-top: 20px;
        }
        li {
          @include mq(tbl) {
            @include fs(16,24);
          }
          a {
            display: inline-block;
            position: relative;
            padding: 10px 0 10px 25px;
            color: $main;
            text-decoration: underline;
            @include mq(tbl) {
              padding: 7px 0 7px 25px;
            }
            &:before {
              @include pd;
              background: url(#{$imagepath}arrow_right_white.svg);
              width: 16px;
              height: 16px;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
            &:hover {
              @include mq(tbl) {
                text-decoration: none;
              }
            }
          }
        }
      }

    }
    .logos {
      text-align: center;
      padding: 20px 0;
      @include mq(tbl) {
        padding: 100px 0 0;
      }
      img {
        width: 300px;
        @include mq(tbl) {
          width: 360px;
        }
      }
    }
  }
}
