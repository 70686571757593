@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_speaker {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 60px;
  }
  .inner {
    @include container;
    @include mq(tbl) {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: flex-start;
      gap: 5%;
    }
    .speaker {
      background-color: $main;
      padding: 20px 30px 35px;
      border-radius: 6px;
      color: $white;
      @include mq(tbl) {
        padding: 30px 30px 40px;
        width: 32%;
        margin-top: 5px;
        position: sticky;
        top: 0;
        &.static {
          position: static;
        }
      }
      .heading {
        @include fs(16,26);
        text-align: center;
        font-weight: $font-weight-bold;
        @include mq(tbl) {
          @include fs(18,28);
        }
      }
      .unit {
        margin-top: 10px;
        @include mq(tbl) {
          margin-top: 15px;
        }
        .photo {
          text-align: center;
          img {
            max-width: 160px;
            border-radius: 50%;
          }
        }
        .name {
          margin-top: 20px;
          text-align: center;
          @include mq(tbl) {
            margin-top: 20px;
          }
          dt {
            margin-bottom: 2px;
            @include fs(22,30);
            font-weight: $font-weight-bold;
            @include mq(tbl) {
              margin-bottom: 2px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            &.en {
              @include mq(tbl) {
                @include fs(13,20);
              }
            }
          }
          dd {
            @include fs(12,18);
            margin-bottom: 12px;
            color: #EBEBEB;
            @include mq(tbl) {
              margin-bottom: 18px;
            }
          }
        }
        .profile {
          border-top: 1px solid $white;
          margin-top: 10px;
          @include fs(14,20);
          padding-top: 19px;
        }
      }
    }
    .text {
      margin-top: 40px;
      @include mq(tbl) {
        width: 61%;
        margin-top: 0;
      }
      .type_movie {
        margin-top: 20px;
        @include mq(tbl) {
          margin-top: 20px;
          padding-bottom: 40px;
        }
        .container {
          padding: 0;
          display: block;
        }
      }
      .type_column,
      .type_sub {
        margin-top: 15px;
        padding-bottom: 20px;
        @include mq(tbl) {
          margin-top: 20px;
          padding-bottom: 40px;
        }
        .container {
          padding: 0;
          display: block;
          .inner {
            .text {
              margin-top: 0;
              width: 100%;
            }
          }
        }
      }
      .type_table {
        margin-top: 15px;
        padding-bottom: 20px;
        padding-left: 0;
        padding-right: 0;
        @include mq(tbl) {
          margin-top: 20px;
          padding-bottom: 40px;
        }
        .inner {
          padding: 0;
          display: block;
          .table_set {
            table {
              th,td {
                @include fs(13,26);
                padding: 8px 10px;
                @include mq(tbl) {
                  @include fs(16,32);
                  padding: 10px 20px;
                }
              }
            }
            &.compact {
              table {
                th,td {
                  @include fs(13,18);
                  padding: 4px 5px;
                  @include mq(tbl) {
                    @include fs(16,22);
                    padding: 10px 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
