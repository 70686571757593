@charset "UTF-8";
@import "variables";
@import "mixins";

.m_cattitle {
  position: relative;
  overflow: hidden;
  .bg {
    position: absolute;
    background-color: $sub;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2;
    background-position: center;
    background-size: cover;
    border-bottom-right-radius: 2000px 355px;
    border-bottom-left-radius: 2000px 355px;
    margin-left: -200px;
    margin-right: -200px;
    padding-left: 200px;
    padding-right: 200px;
    height: 225px;
    @include mq(tbl) {
      height: 355px;
    }
  }
  .inner {
    @include container;
    z-index: 3;
    position: relative;
    display: flex;
    align-items: center;
    height: 225px;
    @include mq(tbl) {
      height: 355px;
    }
    .en {
      display: block;
      text-align: center;
      padding-bottom: 10px;
      @include fs(14,14);
      color: $white;
      @include mq(tbl) {
        @include fs(14,14);
      }
    }
    .title {
      text-align: left;
      @include fs(26,34);
      font-family: $font-family-main;
      font-weight: $font-weight-bold;
      color: $white;
      letter-spacing: 3px;
      @include mq(tbl) {
        @include fs(32,46);
        letter-spacing: 5px;
      }
    }
  }
}
