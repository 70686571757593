@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_image_multi {
  margin-top: 25px;
  @include mq(tbl) {
    margin-top: 40px;
  }
  .inner {
    @include container;
    @include mq(tb) {
      display: flex;
      justify-content: center;
      gap: 25px;
    }
    .image {
      padding-bottom: 25px;
      @include mq(tb) {
        padding-bottom: 0;
        flex: 1;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
