@charset "UTF-8";
@import "variables";
@import "mixins";

.m_catalogindex {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 60px;
  }
  .inner {
    @include container;
    @include mq(tbl) {
      display: flex;
      justify-content: space-between;
      gap: 6%;
    }
    .image {
      text-align: center;
      @include mq(tbl) {
        width: 26%;
      }
      a {
        @include mq(tbl) {
          @include hoverOp;
        }
      }
      img {
        max-width: 300px;
        @include mq(tbl) {
          max-width: none;
        }
      }
    }
    .index {
      margin-top: 40px;
      zoom:1.1;
      line-height: 1.8;
      @include mq(tbl) {
        margin-top: 0;
        width: 68%;
        zoom:1.3;
      }
    }
  }
}
