@charset "UTF-8";
@import "variables";
@import "mixins";

.m_articlelist {
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  &.bg {
    background-color: #F3F5F6;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 0;
    @include mq(tbl) {
      padding-top: 70px;
      padding-bottom: 80px;
    }
  }
  .inner {
    @include container;
    .list {
      margin-top: 40px;
      @include mq(tbl) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fit, 1fr);
        grid-gap: 45px 45px;
      }
      .unit {
        display: flex;
        position: relative;
        margin-top: 30px;
        gap: 20px;
        @include mq(tbl) {
          margin-top: 0;
          gap: 28px;
        }
        &:first-child {
          margin-top: 0;
        }
        .image {
          flex: 0 1 auto;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            // background: #E2F3F8 center / 50% no-repeat url(/images/logomark.svg);
            height: 100px;
            width: 100px;
            border-radius: 10px;
            @include mq(tbl) {
              height: 200px;
              width: 200px;
              @include hoverOp;
            }
            img {
              max-height: 100px;
              max-width: 100px;
              width: auto;
              border-radius: 10px;
              @include mq(tbl) {
                max-height: 200px;
                max-width: 200px;
              }
            }
          }
        }
        .desc {
          flex: 1;
          .cat {
            padding-bottom: 5px;
            @include mq(tbl) {
              padding-bottom: 12px;
            }
            a {
              display: inline-block;
              margin: 0 5px 5px 0;
              border: 1px solid $main;
              @include fs(10,10);
              background-color: $white;
              padding: 4px 10px;
              border-radius: 12px;
              color: $black;
              @include mq(tbl) {
                @include fs(11,11);
                padding: 5px 20px;
                @include hoverOp;
              }
            }
          }
          .date {
            color: $black;
            font-weight: $font-weight-bold;
            @include fs(12,12);
            @include mq(tbl) {
              @include fs(13,13);
            }
          }
          .title {
            @include fs(15,24);
            margin-top: 10px;
            position: relative;
            padding-left: 22px;
            color: $main-dark;
            font-weight: $font-weight-bold;
            @include mq(tbl) {
              padding-left: 26px;
            }
            &:before {
              @include pd;
              background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
              width: 16px;
              height: 16px;
              top: 12px;
              left: 0;
              transform: translateY(-50%);
              @include mq(tbl) {
                top: 15px;
              }
            }
            a {
              color: $main-dark;
            }
            @include mq(tbl) {
              @include fs(18,28);
              @include hoverOp;
            }
          }
          .summary {
            @include fs(11,20);
            margin-top: 6px;
            @include mq(tbl) {
              @include fs(14,26);
            }
          }
        }
      }
    }
    .more {
      margin-top: 40px;
      text-align: center;
    }
  }
}
