@charset "UTF-8";
@import "variables";
@import "mixins";

.m_search {
  margin-top: 30px;
  position: relative;
  @include mq(tbl) {
    margin-top: 80px;
  }
  + .m_general.type_heading {
    margin-top: 20px;
    @include mq(tbl) {
      margin-top: 0px;
    }
  }
  .obj {
    display: none;
    @include mq(tbl) {
      display: block;
      z-index: 4;
      left: calc(50% - 680px);
      top: 25%;
    }
  }
  .inner {
    @include container;
  }
  .unit {
    border-top: 1px solid #B7B7B7;
    padding: 23px 0 32px;
    @include mq(tbl) {
      display: flex;
      justify-content: space-between;
      padding: 0;
    }
    .title {
      text-align: center;
      @include fs(11,16);
      font-weight: $font-weight-bold;
      letter-spacing: 2px;
      @include mq(tbl) {
        flex: 1;
        text-align: left;
        @include fs(16,26);
        padding: 40px 32px;
      }
      strong {
        @include fs(18,26);
        @include mq(tbl) {
          @include fs(26,36);
        }
      }
    }
    &.map {
      @include mq(tbl) {
        display: block;
        padding-bottom: 50px;
      }
      .title {
        @include mq(tbl) {
          padding-bottom: 20px;
        }
        .sp {
          @include mq(tbl) {
            display: none;
          }
        }
        .pc {
          display: none;
          @include mq(tbl) {
            display: inline-block;
          }
        }
      }
    }
    // area
    .area {
      margin-top: 20px;
      @include mq(tbl) {
        margin-top: 0;
        width: 710px;
        align-self: center;
      }
      ul {
        border-radius: 26px;
        border: 1px solid $main;
        display: flex;
        align-items: center;
        padding: 6px 15px;
        @include fs(14,14);
        @include mq(tbl) {
          @include fs(16,16);
          padding: 6px 25px;
        }
        li {
          &.select {
            select {
              border: none;
              outline: none;
              background: transparent;
              font: inherit;
            }
          }
          &.text {
            flex: 1;
            margin-left: 15px;
            @include mq(tbl) {
              margin-left: 25px;
            }
            input {
              width: 100%;
              font: inherit;
              border: none;
              border-left: 1px solid #B7B7B7;
              padding: 3px 15px;
              @include mq(tbl) {
                padding: 3px 25px;
              }
            }
          }
          &.button {
            button {
              background-color: transparent;
              border: none;
              cursor: pointer;
              outline: none;
              padding: 5px 10px;
              appearance: none;
              @include mq(tbl) {
                @include hoverOp;
              }
            }
          }
        }
      }
    }
    // condition
    .condition {
      margin-top: 20px;
      @include mq(tbl) {
        margin-top: 0;
        width: 710px;
        padding: 50px 5px 50px 0;
      }
      .main {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fit, 1fr);
        grid-gap: 15px 10px;
        @include mq(tb) {
          grid-template-columns: repeat(4, 1fr);
        }
        @include mq(tbl) {
          grid-template-columns: repeat(3, 1fr);
        }
        li {
          @include fs(13,13);
          letter-spacing: 0;
          @include mq(tbl) {
            @include fs(15,15);
          }
          label {
            display: flex;
            align-items: center;
            @include mq(tbl) {
              @include hoverOp;
            }
            i {
              margin: 0 3px;
              width: 30px;
              height: 28px;
              display: flex;
              align-items: center;
              justify-content: center;
              @include mq(tbl) {
                width: 40px;
                height: 36px;
                margin: 0 10px;
              }
              img {
                max-height: 100%;
              }
            }
          }
        }
      }
      .sub {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fit, 1fr);
        grid-gap: 15px 10px;
        background-color: #F5F5F5;
        padding: 20px;
        margin-top: 20px;
        @include mq(tb) {
          grid-template-columns: repeat(4, 1fr);
        }
        @include mq(tbl) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 20px 10px;
          padding: 25px;
        }
        li {
          @include fs(13,13);
          letter-spacing: 0;
          @include mq(tbl) {
            @include fs(14,14);
          }
          label {
            display: flex;
            align-items: center;
            @include mq(tbl) {
              @include hoverOp;
            }
            input {
              margin-right: 15px;
            }
          }
        }
      }
      button {
        background-color: transparent;
        cursor: pointer;
        outline: none;
        appearance: none;
        border-radius: 26px;
        border: 1px solid $main;
        display: flex;
        align-items: center;
        font: inherit;
        padding: 10px 15px;
        @include fs(14,14);
        margin: 25px auto 0;
        min-width: 147px;
        align-items: center;
        justify-content: center;
        font-weight: $font-weight-bold;
        letter-spacing: 2px;
        @include mq(tbl) {
          @include hoverOp;
          @include fs(15,15);
          padding: 12px 18px;
          min-width: 160px;
          margin: 30px 0 0 auto;
        }
        img {
          width: 19px;
          margin-right: 14px;
        }
      }
    }
    // pref
    .pref {
      margin-top: 28px;
      border-bottom: 1px solid #DBDBDB;
      @include mq(tbl) {
        border: none;
        margin: 0 auto;
        position: relative;
        background: center top / 564px 470px no-repeat url(/images/search_map.svg);
        width: 975px;
        height: 576px;
      }
      .box {
        border-top: 1px solid #DBDBDB;
        @include mq(tbl) {
          border: none;
          position: absolute;
          border: 1px solid $black;
          border-radius: 10px;
          padding: 0 11px 11px;
          background-color: $white;
          z-index: 2;
          pointer-events: none;
          &.hokkaido {
            right: 125px;
            top: 0;
            border-color: #7CD3C9;
            h3 {
              color: #7CD3C9 !important;
            }
            .child ul li a.active {
              background-color: #7CD3C9;
            }
            .child {
              ul {
                @include mq(tbl) {
                  grid-template-columns: repeat(1, 1fr);
                }
              }
            }
          }
          &.tohoku {
            right: 0;
            top: 117px;
            border-color: #837CD3;
            h3 {
              color: #837CD3 !important;
            }
            .child ul li a.active {
              background-color: #837CD3;
            }
          }
          &.kanto {
            right: 0;
            top: 266px;
            border-color: #D37CBD;
            h3 {
              color: #D37CBD !important;
            }
            .child ul li a.active {
              background-color: #D37CBD;
            }
          }
          &.hokuriku {
            left: 323px;
            top: 64px;
            border-color: #D5D556;
            h3 {
              color: #D5D556 !important;
            }
            .child ul li a.active {
              background-color: #D5D556;
            }
          }
          &.kansai {
            left: 536px;
            bottom: 0;
            border-color: #EB8B61;
            h3 {
              color: #EB8B61 !important;
            }
            .child ul li a.active {
              background-color: #EB8B61;
            }
            .child {
              ul {
                @include mq(tbl) {
                  grid-template-columns: repeat(2, 1fr);
                }
              }
            }
          }
          &.chugoku {
            left: 31px;
            top: 203px;
            border-color: #6FBA2C;
            h3 {
              color: #6FBA2C !important;
            }
            .child ul li a.active {
              background-color: #6FBA2C;
            }
          }
          &.shikoku {
            left: 343px;
            bottom: 0;
            border-color: #56B6D9;
            h3 {
              color: #56B6D9 !important;
            }
            .child ul li a.active {
              background-color: #56B6D9;
            }
            .child {
              ul {
                @include mq(tbl) {
                  grid-template-columns: repeat(2, 1fr);
                }
              }
            }
          }
          &.kyushu {
            left: 0;
            bottom: 62px;
            border-color: #CEB23D;
            h3 {
              color: #CEB23D !important;
            }
            .child ul li a.active {
              background-color: #CEB23D;
            }
          }
        }
        &.haschild {
          h3 {
            color: $black;
            @include mq(tbl) {
              pointer-events: none;
            }
            position: relative;
            &:after, &:before {
              @include pd;
              right: 8px;
              top: 50%;
              background-color: #8E8E8E;
              width: 13px;
              height: 1px;
              @include mq(tbl) {
                display: none;
              }
            }
            &:after {
              width: 1px;
              height: 13px;
              right: 14px;
              margin-top: -6px;
              transition: transform $bezier $transition-speed;
            }
            &.active {
              &:after {
                transform: scaleY(0);
              }
            }
          }
        }
        h3 {
          padding: 14px 10px;
          @include fs(13,13);
          font-weight: $font-weight-bold;
          color: #A2A2A2;
          @include mq(tbl) {
            @include fs(14,14);
            text-align: center;
            padding: 11px 0;
          }
        }
        .child {
          display: none;
          @include mq(tbl) {
            display: block !important;
          }
          ul {
            padding: 6px 6px 20px 70px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(auto-fit, 1fr);
            grid-gap: 10px 10px;
            @include mq(tb) {
              grid-template-columns: repeat(4, 1fr);
            }
            @include mq(tbl) {
              grid-template-columns: repeat(3, 1fr);
              padding: 0;
              grid-gap: 5px 5px;
            }
            li {
              a {
                @include button();
                background-color: $white;
                color: #A2A2A2 !important;
                @include fs(14,14);
                padding: 11px 43px 11px 22px;
                border: 1px solid #DBDBDB;
                word-spacing: 100%;
                display: block;
                pointer-events: none;
                @include mq(tbl) {
                  border-radius: 6px;
                  @include hoverOp(.7);
                  @include fs(15,15);
                  letter-spacing: 0px;
                  background-color: #F3F3F3;
                  border: none;
                  width: 70px;
                  padding: 10px 0;
                  text-align: center;
                }
                &:before {
                  content: none;
                }
                &.active {
                  pointer-events: all;
                  color: $black !important;
                  @include mq(tbl) {
                    color: $white !important;
                  }
                  &:before {
                    content: '';
                    background-image: url(#{$imagepath}arrow_right_main.svg);
                    width: 15px;
                    height: 15px;
                    right: 13px;
                    @include mq(tbl) {
                      content: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .gps {
      margin: 35px 0 0;
      @include mq(tbl) {
        display: none;
      }
      a {
        border-radius: 26px;
        border: 1px solid $main;
        padding: 14px 25px;
        @include fs(14,14);
        font-weight: $font-weight-bold;
        letter-spacing: 2px;
        position: relative;
        display: block;
        max-width: 325px;
        margin: 0 auto;
        &:before {
          @include pd;
          background-image: url(#{$imagepath}arrow_right_main.svg);
          width: 15px;
          height: 15px;
          right: 20px;
          @include mq(tbl) {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}
