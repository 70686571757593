@charset "UTF-8";

/* ================================================== *
 *    compass
 * ================================================== */

// @import "compass/reset";
// @import "compass/css3";
// @import "compass/utilities/general";

@import "reset";
@import "variables";
@import "mixins";

* {
   box-sizing: border-box;
}
*:before,
*:after {
   box-sizing: border-box;
}
*:focus {
outline: none;
}
html {
  font-size: 62.5%;
  height: 100%;
  scroll-behavior: smooth;
}
body {
  @include fs(14);
  color: $body-text-color;
  background-color: $body-bg-color;
  font-family: $body-font-family;
  font-weight: $body-font-weight;
  font-feature-settings: $body-font-feature-settings;
  letter-spacing: $body-letter-spacing;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  @if $responsive-debug-mode {
    &:after {
      content: 'min';
      position: fixed;
      left: 10px;
      top: 10px;
      font-size: 12px;
      background-color: red;
      color: white;
      z-index: 1000;
      border-radius: 4px;
      display: inline-block;
      padding: 4px;
      @include mq(sp) {
        content: 'sp';
      }
      @include mq(spl) {
        content: 'sp landscape';
      }
      @include mq(tb) {
        content: 'tablet';
      }
      @include mq(tbl) {
        content: 'tablet landscape';
      }
      @include mq(pc) {
        content: 'pc';
      }
    }
  }
  &.fixed {
    position: fixed;
    width: 100%;
  }
}

.red {
  color: $red;
}
strong {
  font-weight: bold;
}
label {
  cursor: pointer;
}
input,select,textarea,label,a {
  outline: none;
}
a {
  color: $body-text-color;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  &[href^="tel:"] {
    color: inherit;
  }
  &:focus {
    outline: none;
  }
}
abbr {
  text-decoration: none;
}
button {
  outline: none;
  cursor: pointer;
}
sup,
sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  position: relative;
  font-size: 0.75em;
}
sup {
  bottom: .75ex;
}
sub {
  top: .5ex;
}
img {
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  @include mq(tbl) {
    width: auto;
  }
}
.hidden-over-tbl {
  @include mq(tbl) {
    display: none;
  }
}
.hidden-over-tb {
  @include mq(tb) {
    display: none;
  }
}
.hidden-over-sp {
  @include mq(sp) {
    display: none;
  }
}
.hidden-sp {
  @media (max-width: 374px) {
    display: none;
  }
}
.hidden-tb {
  @media (max-width: 767px) {
    display: none;
  }
}
.hidden-tbl {
  @media (max-width: 1079px) {
    display: none;
  }
}

.anchor {
  position: relative;
  top: 0;
  display:block;
  visibility:hidden;
  color: transparent;
  &_sub {
    position: relative;
    top: 0;
    display:block;
    visibility:hidden;
    color: transparent;
    @include mq(tbl) {
      top: -100px;
    }
  }
}
.svg_def {
  visibility:hidden;
  width: 0;
  height: 0;
  position: absolute;
}
.container {
  @include container;
}
main {
  transition: opacity .2s $bezier;
  padding-top: $header-height;
  @include mq(tbl) {
    padding-top: $header-height-pc;
  }
  .toppage & {
    padding-top: 0;
  }
  .sink & {
    opacity: .5;
    @include mq(tbl) {
      opacity: 1;
    }
  }
}
//default
@import "header";
@import "footer";
@import "parts";
@import "top";
@import "modules/mv";
@import "modules/message";
@import "modules/stores";
@import "modules/articlelist";
@import "modules/itemlist";
@import "modules/pagetitle";
@import "modules/cattitle";
// @import "modules/extensiontitle";
@import "modules/articletitle";
@import "modules/localnav";
@import "modules/localnav_sub";
@import "modules/archivenav";
@import "modules/breadcumbs";
@import "modules/eyecatch";
@import "modules/filter";
@import "modules/pagenate";
@import "modules/contact";
@import "modules/aform";
@import "modules/slider";
// uniq
@import "modules/localnav_bottom";
@import "modules/newslist";
@import "modules/logos";
@import "modules/indexlist";
// @import "modules/bannerlist";
@import "modules/catalogindex";
@import "modules/search";
@import "modules/searchresult";
@import "modules/areamap";
@import "modules/form";
// general
@import "modules/general";
// @import "uniq";
// @import "modules";
// @import "top";
@import "tippy";
