@charset "UTF-8";
@import "variables";
@import "mixins";

.m_breadcumbs {
  margin-top: 10px;
  @include mq(tbl) {
    margin-top: 20px;
  }
  .inner {
    @include container;
    ul {
      @include fs(10,14);
      display: flex;
      flex-wrap: wrap;
      @include mq(tbl) {
        @include fs(12,16);
      }
      li {
        padding-right: 12px;
        margin-right: 8px;
        color: #898989;
        position: relative;
        @include mq(tbl) {
          padding-right: 16px;
          margin-right: 12px;
          font-weight: $font-weight-bold;
        }
        &:after {
          @include pd;
          background: center / contain no-repeat url(#{$imagepath}arrow_breadcrumb.svg);
          width: 5px;
          height: 6px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &:last-child {
          padding-right: 0;
          margin-right: 0;
          &:after {
            content: none;
          }
        }
        a {
          color: #898989;
          @include hoverOp;
        }
      }
    }
  }
}
