@charset "UTF-8";
@import "variables";
@import "mixins";

.m_areamap {
  margin-top: 50px;
  @include mq(tbl) {
    margin-top: 80px;
  }
  .inner {
    @include container;
    .gmap {
      background: #E2F3F8 center / 30% no-repeat url(/images/logomark.svg);
      height: 66.6vw;
      width: 100%;
    }
    .caption {
      @include fs(12,20);
      text-align: center;
      margin-top: 8px;
      color: rgba($body-text-color, 0.75);
      font-weight: $font-weight-bold;
      @include mq(tbl) {
        @include fs(13,22);
        margin-top: 16px;
      }
      a {
        color: $main-dark;
      }
    }
    .gps {
      margin: 25px 0 0;
      padding-bottom: 15px;
      @include mq(tbl) {
        display: none;
      }
      a {
        border-radius: 26px;
        border: 1px solid $main;
        padding: 14px 25px;
        @include fs(14,14);
        font-weight: $font-weight-bold;
        letter-spacing: 2px;
        position: relative;
        display: block;
        max-width: 325px;
        margin: 0 auto;
        &:before {
          @include pd;
          background-image: url(#{$imagepath}arrow_right_main.svg);
          width: 15px;
          height: 15px;
          right: 20px;
          @include mq(tbl) {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}
