@charset "UTF-8";
@import "variables";
@import "mixins";

.m_general.type_wysiwyg {
  margin-top: 20px;
  @include mq(tbl) {
    margin-top: 40px;
  }
  .inner {
    @include container;
  }
}
